<template>
  <div class="main">
    <a-layout class="mlayout">
      <a-layout>
        <a-layout-content
          class="mobile indexLayoutContent"
          style="padding: 0px"
        >
          <div class="page mindex flex-col justify-start">
            <top-b
              ref="topb"
              :kind="`index`"
              :topbarhide="topbarhide"
              :tb="tb"
              :w750="w750"
              :topbarOpacity="topbarOpacity"
              :lag="lag"
              :bmshow="bmshow"
              @bmclose="bmclose"
              :bmstate="bmstate"
              :bmid="bmid"
              :bkind="bkind"
            ></top-b>
            <div class="mswiper windowheight">
              <div class="swiper sc">
                <div :class="`swiper-wrapper`">
                  <div :class="`swiper-slide ssm ssm0`">
                    <div class="group_1 flex-col windowheight">
                      <div v-if="clist90.length > 0" class="swiper sc0">
                        <div class="swiper-wrapper">
                          <template v-for="(item, index) in clist90">
                            <div
                              :class="`swiper-slide ssl0 ss${index}`"
                              :key="index"
                              :style="`background-image:url(${swiper0backimg});opacity:${
                                1 - opy1
                              };`"
                            >
                              <div
                                :class="`mask mk0 ${
                                  index == 0 ? 'mkmaskshow' : ''
                                }`"
                                :style="`background-image:url(${
                                  baseUrl + item.imgurl
                                });`"
                                @click="
                                  () => {
                                    godetall(
                                      item.url,
                                      item.pname,
                                      item.kind,
                                      item.id
                                    );
                                  }
                                "
                              ></div>
                            </div>
                          </template>
                        </div>
                      </div>

                      <div class="sc0zb"></div>

                      <div class="mkdots">
                        <template v-for="(item, index) in clist90">
                          <div
                            :key="index"
                            :class="`md ${
                              mdselectIndex == index ? 'mdselected' : ''
                            }`"
                          ></div>
                        </template>
                      </div>
                    </div>
                  </div>
                  <div :class="`swiper-slide ssm ssm1`">
                    <div class="group_2 flex-row justify-center">
                      <div class="group_2_in flex-col justify-between">
                        <div class="group_2_1 flex-col">
                          <div
                            class="textitem"
                            style="
                              margin-top: 25rem;
                              border-top: 1px solid #ccc;
                            "
                          ></div>
                          <div class="textitem" style="margin-top: 32px">
                            All
                            Fields趣野无限，一个致力于探索户外生活方式的文化传播交流平台。
                            <br />
                            <br />
                            我们特设「<span class="bt">趣野CLUB</span
                            >」、「<span class="bt">趣野好物</span>」、「<span
                              class="bt"
                              >趣野风向</span
                            >」等入口，引领多元户外生活体验、整合各圈层户外品牌、集结户外潮流文化动向，从活动、商品、资讯等多维度，为喜爱户外的人群提供一站式、可持续、个性化的户外生活方式解决方案。
                            <br />
                            <br />
                          </div>
                        </div>
                        <div class="group_2_2 flex-col">
                          <span class="text_12">
                            <img
                              src="https://cdn.moheweb.com/vdb/lweb/imgs/allfields1.png"
                              style="width: 680px"
                            />
                          </span>
                        </div>
                      </div>
                      <img
                        class="backimg"
                        src="../assets/limgs/mb.png"
                        style="object-fit: cover"
                      />
                    </div>
                  </div>
                  <div :class="`swiper-slide ssm ssm2`">
                    <div class="swiper lsc0">
                      <div class="swiper-wrapper">
                        <div class="swiper-slide">
                          <div
                            class="group_3 flex-col"
                            :style="`min-height:${pageHeight}px;`"
                          >
                            <div
                              class="g3 flex-col"
                              :style="`opacity:${1 - opy3};`"
                            >
                              <div class="g3in flex-col">
                                <div class="gbar flex-row justify-between">
                                  <div
                                    :class="`gtitles flex-col ${
                                      mselectIndex == 2 ? 'gtshow' : ''
                                    }`"
                                  >
                                    <span data-v-3f1e1d78="" class="text_13">
                                      <img
                                        src="https://cdn.moheweb.com/vdb/lweb/imgs/title4.png"
                                        style="height: 68px"
                                      />
                                    </span>
                                    <span data-v-3f1e1d78="" class="text_14"
                                      >当户外成为生活常态</span
                                    >
                                  </div>

                                  <div class="btns flex-col">
                                    <router-link :to="`/clubm/0/${lag}`">
                                      <div
                                        class="lbtn flex-row justify-center"
                                        style="margin-right: 4rem"
                                      >
                                        <div
                                          class="lbtnin flex-row justify-between"
                                        >
                                          <span class="text-group_123"
                                            >了解更多</span
                                          >

                                          <img
                                            data-v-3f1e1d78=""
                                            referrerpolicy="no-referrer"
                                            src="https://cdn.moheweb.com/vdb/lweb/imgs/wj.png"
                                            class="image_186"
                                          />
                                        </div>
                                      </div>
                                    </router-link>
                                    <router-link :to="`/clublistm/0/${lag}`">
                                      <div
                                        class="lbtn flex-row justify-center"
                                        style="margin-top: 20px"
                                      >
                                        <div
                                          class="lbtnin flex-row justify-between"
                                        >
                                          <span class="text-group_123"
                                            >报名通道</span
                                          >

                                          <img
                                            data-v-3f1e1d78=""
                                            referrerpolicy="no-referrer"
                                            src="https://cdn.moheweb.com/vdb/lweb/imgs/wj.png"
                                            class="image_186"
                                          />
                                        </div>
                                      </div>
                                    </router-link>
                                  </div>
                                </div>

                                <div class="plist m0 moveing" style="top: 0px">
                                  <template v-for="(item, index) in clist40">
                                    <div
                                      :key="index"
                                      v-if="index == 0"
                                      :class="`pitem ${
                                        mselectIndex == 2 ? 'showpitem' : ''
                                      }`"
                                      :style="`
                                        background-image: url('${
                                          baseUrl + item.imgurl
                                        }');
                                        top: 260px;
                                        left: 36px;
                                        animation-delay: 0s;
                                        `"
                                    >
                                      <div
                                        class="pinfo"
                                        @click="
                                          () => {
                                            godetall(
                                              item.url,
                                              item.pname,
                                              item.kind,
                                              item.id
                                            );
                                          }
                                        "
                                      >
                                        <div class="micon"></div>
                                        <div
                                          class="ptitle"
                                          v-html="item.pname"
                                        ></div>
                                        <div
                                          class="pdes"
                                          v-html="item.des"
                                        ></div>
                                      </div>
                                    </div>
                                    <div
                                      :key="index"
                                      v-if="index == 1"
                                      :class="`pitem ${
                                        mselectIndex == 2 ? 'showpitem' : ''
                                      }`"
                                      :style="`
                                        background-image: url('${
                                          baseUrl + item.imgurl
                                        }');
                                        transform: scale(0.65);
                                        left: 300px;
                                        top: 600px;
                                        animation-delay: .4s;
                                        `"
                                    >
                                      <div
                                        class="pinfo"
                                        @click="
                                          () => {
                                            godetall(
                                              item.url,
                                              item.pname,
                                              item.kind,
                                              item.id
                                            );
                                          }
                                        "
                                      >
                                        <div class="micon"></div>
                                        <div
                                          class="ptitle"
                                          v-html="item.pname"
                                        ></div>
                                        <div
                                          class="pdes"
                                          v-html="item.des"
                                        ></div>
                                      </div>
                                    </div>
                                    <div
                                      :key="index"
                                      v-if="index == 2"
                                      :class="`pitem ${
                                        mselectIndex == 2 ? 'showpitem' : ''
                                      }`"
                                      :style="`background-image: url('${
                                        baseUrl + item.imgurl
                                      }'); transform: scale(1.1);left: 120px; top: 1000px;}; animation-delay: .8s;`"
                                    >
                                      <div
                                        class="pinfo"
                                        @click="
                                          () => {
                                            godetall(
                                              item.url,
                                              item.pname,
                                              item.kind,
                                              item.id
                                            );
                                          }
                                        "
                                      >
                                        <div class="micon"></div>
                                        <div
                                          class="ptitle"
                                          v-html="item.pname"
                                        ></div>
                                        <div
                                          class="pdes"
                                          v-html="item.des"
                                        ></div>
                                      </div>
                                    </div>
                                  </template>
                                </div>
                                <div class="zsqlist">
                                  <div class="zsqitem zi0 zsqselected"></div>
                                  <div class="zsqitem zi1"></div>
                                  <div class="zsqitem zi2"></div>
                                  <div class="zsqitem zi3"></div>
                                  <div class="zsqitem zi4"></div>
                                  <div class="zsqitem zi5"></div>
                                  <div class="zsqitem zi6"></div>
                                </div>
                              </div>

                              <img
                                class="backimgback1"
                                src="https://cdn.moheweb.com/vdb/lweb/imgs/back1_3.png"
                              />
                              <div class="backimg"></div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div :class="`swiper-slide ssm ssm3`">
                    <div class="swiper lsc1">
                      <div class="swiper-wrapper">
                        <div class="swiper-slide">
                          <div class="group_4">
                            <div
                              class="g4 flex-col"
                              :style="`min-height:${pageHeight}px;`"
                            >
                              <div class="g4in flex-col">
                                <!--展会页头-->
                                <div class="gbar flex-col justify-between">
                                  <div
                                    :class="`gtitles flex-col m1 ${
                                      mselectIndex == 3 ? 'gtshow' : ''
                                    }`"
                                  >
                                    <span data-v-3f1e1d78="" class="text_13">
                                      <img
                                        src="https://cdn.moheweb.com/vdb/lweb/imgs/title3.png"
                                        style="height: 68px"
                                      />
                                    </span>
                                    <span data-v-3f1e1d78="" class="text_14"
                                      >一起来期待 ALL FIELDS 趣野无限的首次正式亮相</span
                                    >
                                  </div>

                                  <img
                                    class="image_13"
                                    referrerpolicy="no-referrer"
                                    style="
                                      width: 510px;
                                      height: 1px;
                                      margin: 60px 0 0 0px;
                                    "
                                    src="https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPngd8f4410b8586cbf2d332f9e80509bbea4e70f7b4e9aa4394474c41f867fc9db0"
                                  />

                                  <div class="gdes flex-col">
                                    <div
                                      class="textline"
                                      style="
                                        left: 32px;
                                        top: 254px;
                                        width: 510px;
                                        font-size: 22px;
                                        font-family: SourceHanSansCN-Light;
                                        font-weight: 300;
                                        text-align: justify;
                                        line-height: 34px;
                                        margin-top: 20px;
                                      "
                                    >
                                      All&nbsp;Fields趣野无限联合上海体博会将于2023年11月23-25日在世博展览馆全面露出…
                                    </div>

                                    <div
                                      class="btns flex-row justify-start"
                                      style="margin-top: 20px"
                                    >
                                      <div
                                        class="lbtn flex-row justify-center"
                                        style="margin-right: 32px"
                                        @click="
                                          () => {
                                            //bmopen(data33.id);
                                            bmopen();
                                          }
                                        "
                                      >
                                        <div
                                          class="lbtnin flex-row justify-between"
                                        >
                                          <span class="text-group_123"
                                            >展商通道</span
                                          >

                                          <img
                                            data-v-3f1e1d78=""
                                            referrerpolicy="no-referrer"
                                            src="https://cdn.moheweb.com/vdb/lweb/imgs/wj.png"
                                            class="image_186"
                                          />
                                        </div>
                                      </div>

                                      <div
                                        class="lbtn flex-row justify-center"
                                        @click="
                                          () => {
                                            godetall(
                                              data33.url,
                                              data33.pname,
                                              data33.kind,
                                              data33.id
                                            );
                                          }
                                        "
                                      >
                                        <div
                                          class="lbtnin flex-row justify-between"
                                        >
                                          <span class="text-group_123"
                                            >观众通道</span
                                          >

                                          <img
                                            data-v-3f1e1d78=""
                                            referrerpolicy="no-referrer"
                                            src="https://cdn.moheweb.com/vdb/lweb/imgs/wj.png"
                                            class="image_186"
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                <!--展会列表-->

                                <div class="zlist flex-row">
                                  <template v-for="(item, index) in clist31">
                                    <div
                                      class="zitemf flex-row justify-between"
                                      :key="index"
                                      @click="
                                        () => {
                                          godetall(
                                            item.url,
                                            item.pname,
                                            item.kind,
                                            item.id
                                          );
                                        }
                                      "
                                    >
                                      <div class="zt">
                                        <div class="zimg">
                                          <img
                                            referrerpolicy="no-referrer"
                                            :src="
                                              item.imgurl.indexOf('https://') ==
                                              -1
                                                ? baseUrl + item.imgurl
                                                : item.imgurl
                                            "
                                          />
                                        </div>
                                        <div class="title">
                                          {{ item.pname }}
                                        </div>
                                        <div class="des">{{ item.des }}</div>
                                      </div>
                                    </div>
                                  </template>
                                </div>

                                <div style="position: relative; width: 750px">
                                  <router-link :to="`/showm/0/${lag}`">
                                    <div
                                      class="lbtn flex-row justify-center"
                                      style="
                                        margin-left: 8rem;
                                        margin-top: 60px;
                                        margin-bottom: 80px;
                                      "
                                    >
                                      <div
                                        class="lbtnin flex-row justify-between"
                                      >
                                        <span class="text-group_123"
                                          >了解更多</span
                                        >

                                        <img
                                          data-v-3f1e1d78=""
                                          referrerpolicy="no-referrer"
                                          src="https://cdn.moheweb.com/vdb/lweb/imgs/wj.png"
                                          class="image_186"
                                        />
                                      </div>
                                    </div>
                                  </router-link>
                                </div>
                              </div>
                              <!-- <div class="backimg"></div> -->
                              <img
                                class="backimg"
                                src="../assets/limgs/mb.png"
                                style="object-fit: cover"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div :class="`swiper-slide ssm ssm4`">
                    <div class="group_5 flex-col windowheight">
                      <div class="g5 flex-col">
                        <div class="g5in flex-col">
                          <div class="gbar flex-row justify-between">
                            <div
                              :class="`gtitles flex-col ${
                                mselectIndex == 4 ? 'gtshow' : ''
                              }`"
                            >
                              <span
                                data-v-3f1e1d78=""
                                class="text_13"
                                style="color: rgba(50, 50, 50, 1)"
                              >
                                <img
                                  src="https://cdn.moheweb.com/vdb/lweb/imgs/title2.png"
                                  style="height: 68px"
                                />
                              </span>
                              <span
                                data-v-3f1e1d78=""
                                class="text_14"
                                style="color: rgba(50, 50, 50, 1)"
                                >ALL FIELDS让你「闭眼」购物~</span
                              >
                            </div>

                            <div
                              class="btns flex-row justify-end"
                              style="opacity: 0"
                            >
                              <div
                                class="lmore flex-row justify-center"
                                style="margin-top: 20px; margin-bottom: 0px"
                              >
                                <router-link :to="`/haowum/0/${lag}`">
                                  <div class="lmorein flex-row justify-between">
                                    <span class="text-group_123">更多好物</span>

                                    <img
                                      referrerpolicy="no-referrer"
                                      src="https://cdn.moheweb.com/vdb/lweb/imgs/bj.png"
                                      class="image_186 bjt"
                                    />
                                    <img
                                      referrerpolicy="no-referrer"
                                      src="https://cdn.moheweb.com/vdb/lweb/imgs/wj.png"
                                      class="image_186 wjt"
                                      style="display: none"
                                    />
                                  </div>
                                </router-link>
                              </div>
                            </div>
                          </div>

                          <div class="swiper sc1">
                            <div class="swiper-wrapper">
                              <template v-for="(item, index) in clist20">
                                <div
                                  :class="`swiper-slide ss${index} ${
                                    index == 0 ? 'ssselected' : ''
                                  }`"
                                  :key="index"
                                >
                                  <div class="ppitem">
                                    <div class="ppimg">
                                      <img :src="baseUrl + item.imgurl" />
                                    </div>

                                    <div class="ppinfo">
                                      <div class="pptitle">
                                        {{ item.pname }}
                                      </div>
                                      <div class="ppdes">{{ item.des }}</div>
                                      <div
                                        class="ppprice"
                                        v-if="item.price != '0'"
                                      >
                                        ￥{{ takeprice(item.price) }}
                                      </div>
                                      <div
                                        class="ppbtn"
                                        v-if="item.price != '0'"
                                      >
                                        <a target="_blank" :href="item.url">
                                          <div
                                            class="lmore flex-row justify-center"
                                            style="
                                              margin-top: 0px;
                                              margin-bottom: 0px;
                                              margin-top: 48px;
                                            "
                                          >
                                            <div
                                              class="lmorein flex-row justify-between"
                                            >
                                              <span class="text-group_123"
                                                >立即购买</span
                                              >

                                              <img
                                                referrerpolicy="no-referrer"
                                                src="https://cdn.moheweb.com/vdb/lweb/imgs/bj.png"
                                                class="image_186 bjt"
                                              />

                                              <img
                                                referrerpolicy="no-referrer"
                                                src="https://cdn.moheweb.com/vdb/lweb/imgs/wj.png"
                                                class="image_186 wjt"
                                                style="display: none"
                                              />
                                            </div>
                                          </div>
                                        </a>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </template>
                            </div>
                            <!-- Add Pagination -->
                            <div class="pndiv">
                              <div class="toleft" @click="swiper1Prev"></div>
                              <div class="swiper-pagination"></div>
                              <div class="toright" @click="swiper1Next"></div>
                            </div>
                          </div>
                        </div>
                        <!-- <div class="backimg"></div> -->
                        <img
                          class="backimg"
                          src="../assets/limgs/mb.png"
                          style="object-fit: cover"
                        />
                      </div>

                      <div
                        class="wzd2"
                        v-if="
                          winHeight >
                          group1h + group2h + group3h + group4h + 4000 + 4000
                        "
                        :style="`opacity:${opy5};`"
                      ></div>
                    </div>
                  </div>

                  <div :class="`swiper-slide ssm ssm5`">
                    <div class="swiper lsc2">
                      <div class="swiper-wrapper">
                        <div class="swiper-slide">
                          <div
                            class="group_6 flex-col"
                            :style="`-webkit-mask-size: ${masksize}%; ${
                              masksize == 300 ? '-webkit-mask:none' : ''
                            } `"
                          >
                            <div class="g6gg">
                              <div class="swiper sc6">
                                <div class="swiper-wrapper">
                                  <template v-for="(item, index) in clist91">
                                    <div :class="`swiper-slide`" :key="index">
                                      <div
                                        class="ggimg"
                                        :style="`background-image:url(${
                                          baseUrl + item.imgurl
                                        });`"
                                        @click="
                                          () => {
                                            godetall(
                                              item.url,
                                              item.pname,
                                              item.kind,
                                              item.id
                                            );
                                          }
                                        "
                                      ></div>
                                    </div>
                                  </template>
                                </div>

                                <div class="swiper-pagination sp6"></div>
                              </div>
                            </div>

                            <div class="g6 flex-col">
                              <div class="g6in flex-col">
                                <!--朋友圈页头-->
                                <div class="gbar flex-col justify-between">
                                  <div
                                    :class="`gtitles flex-col m1 ${
                                      mselectIndex == 5 ? 'gtshow' : ''
                                    }`"
                                    style="
                                      display: flex;
                                      flex-direction: column;
                                      align-items: flex-end;
                                      justify-content: center;
                                    "
                                  >
                                    <span
                                      data-v-3f1e1d78=""
                                      class="text_13"
                                      style="width: auto; text-align: right"
                                    >
                                      <img
                                        src="https://cdn.moheweb.com/vdb/lweb/imgs/title1.png"
                                        style="
                                          height: 68px;
                                          margin-right: 160px;
                                        "
                                      />
                                    </span>
                                    <span
                                      data-v-3f1e1d78=""
                                      class="text_14"
                                      style="width: auto; text-align: right"
                                      >听说……这些都是ALL FIELDS的好朋友</span
                                    >

                                    <router-link :to="`/huobanm/0/${lag}`">
                                      <div
                                        class="lmore flex-row justify-center"
                                        style="
                                          margin-top: 30px;
                                          margin-bottom: 30px;
                                          width: 208px;
                                          margin-right: 240px;
                                          opacity: 1;
                                        "
                                      >
                                        <div
                                          class="lmorein flex-row justify-between"
                                        >
                                          <span class="text-group_123"
                                            >了解更多</span
                                          >

                                          <img
                                            referrerpolicy="no-referrer"
                                            src="https://cdn.moheweb.com/vdb/lweb/imgs/bj.png"
                                            class="image_186 bjt"
                                          />
                                          <img
                                            referrerpolicy="no-referrer"
                                            src="https://cdn.moheweb.com/vdb/lweb/imgs/wj.png"
                                            class="image_186 wjt"
                                            style="display: none"
                                          />
                                        </div>
                                      </div>
                                    </router-link>
                                  </div>

                                  <div
                                    class="gdes flex-row justify-between"
                                    style="width: 100%; margin-top: 40px"
                                  >
                                    <div
                                      class="textline"
                                      style="margin-top: 1rem; width: 420px"
                                    >
                                      <div
                                        class="tline"
                                        style="
                                          position: relative;
                                          width: 100%;
                                          border-bottom: 1px solid #aaa;
                                          margin-bottom: 20px;
                                        "
                                      ></div>
                                      户外博主们的日常生活是怎么样的？
                                      <br />
                                      户外品牌主理人每天都在忙什么？
                                      <br />
                                      户外专业大咖是怎样练成的？
                                    </div>
                                  </div>
                                </div>

                                <div class="g6plist">
                                  <div
                                    class="pitem p6pi1"
                                    v-if="flist10.length > 0"
                                    :style="`
                                      background-image: url('${baseUrl}${flist10[0].imgurl}');
                                    `"
                                  >
                                    <router-link :to="`/huobanm/0/${lag}`">
                                      <div class="pinfo">
                                        <div class="micon"></div>
                                        <div
                                          class="title"
                                          v-html="`@${flist10[0].title}`"
                                        ></div>
                                      </div>
                                    </router-link>
                                  </div>

                                  <span data-v-3f1e1d78="" class="text_49"
                                    >快来这里看看All&nbsp;Fields朋友圈的故事，他们并非一定是你仰望的对象，而是身边的你我他。</span
                                  >

                                  <div
                                    class="pitem p6pi2"
                                    v-if="flist10.length > 1"
                                    :style="`
                                      background-image: url('${baseUrl}${flist10[1].imgurl}');
                                    `"
                                  >
                                    <router-link :to="`/huobanm/0/${lag}`">
                                      <div class="pinfo">
                                        <div class="micon"></div>
                                        <div
                                          class="title"
                                          v-html="`@${flist10[1].title}`"
                                        ></div>
                                      </div>
                                    </router-link>
                                  </div>

                                  <div
                                    class="pitem p6pi3"
                                    v-if="flist10.length > 2"
                                    :style="`
                                      background-image: url('${baseUrl}${flist10[2].imgurl}');
                                    `"
                                  >
                                    <router-link :to="`/huobanm/0/${lag}`">
                                      <div class="pinfo">
                                        <div class="micon"></div>
                                        <div
                                          class="title"
                                          v-html="`@${flist10[2].title}`"
                                        ></div>
                                      </div>
                                    </router-link>
                                  </div>

                                  <div
                                    class="pitem p6pi4"
                                    v-if="flist10.length > 3"
                                    :style="`
                                      background-image: url('${baseUrl}${flist10[3].imgurl}');
                                    `"
                                  >
                                    <router-link :to="`/huobanm/0/${lag}`">
                                      <div class="pinfo">
                                        <div class="micon"></div>
                                        <div
                                          class="title"
                                          v-html="`@${flist10[3].title}`"
                                        ></div>
                                      </div>
                                    </router-link>
                                  </div>

                                  <div
                                    class="pitem p6pi5"
                                    v-if="flist10.length > 4"
                                    :style="`
                                      background-image: url('${baseUrl}${flist10[4].imgurl}');
                                    `"
                                  >
                                    <router-link :to="`/huobanm/0/${lag}`">
                                      <div class="pinfo">
                                        <div class="micon"></div>
                                        <div
                                          class="title"
                                          v-html="`@${flist10[4].title}`"
                                        ></div>
                                      </div>
                                    </router-link>
                                  </div>
                                </div>
                                <div class="logolist">
                                  <template v-for="(item, index) in flist11">
                                    <img
                                      :key="index"
                                      class="litem"
                                      :src="baseUrl + item.imgurl"
                                      @click="
                                        () => {
                                          setlogoindex(index);
                                        }
                                      "
                                    />
                                  </template>
                                </div>

                                <div class="g6btitle"></div>
                              </div>
                              <div class="backimg"></div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div :class="`swiper-slide ssm ssm6`">
                    <div class="group_7 flex-col windowheight">
                      <div class="g7 flex-col">
                        <div class="g7in flex-col">
                          <div class="g7list">
                            <div class="g7itemf flex-col">
                              <div class="gbar flex-col justify-between">
                                <div
                                  :class="`gtitles flex-col m1 ${
                                    mselectIndex == 6 ? 'gtshow' : ''
                                  }`"
                                >
                                  <span
                                    data-v-3f1e1d78=""
                                    class="text_13"
                                    style="color: "
                                    >趣野风向</span
                                  >
                                  <span data-v-3f1e1d78="" class="text_14"
                                    >更多视角更多脑洞突破想象</span
                                  >
                                </div>

                                <div class="gdes flex-col">
                                  <img
                                    style="
                                      width: 510px;
                                      height: 1px;
                                      margin-top: 60px;
                                    "
                                    referrerpolicy="no-referrer"
                                    src="https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPng903d0f58fe69ff2e0857602f2f6e8118f0471cc1617f9d48d428b5d75f40d513"
                                  />

                                  <div
                                    class="textline"
                                    style="margin-top: 10px"
                                  >
                                    涵盖热点趋势、亮点单品、兴趣清单、指南宝典、出行攻略等干货内容，毫不吝啬与你分享户外文化，并释出All
                                    Fields对于可持续环保、乡村振兴、潮流时尚等理念的态度和实践。
                                  </div>

                                  <div
                                    class="btns flex-row justify-between"
                                    style="margin-top: 60px"
                                  >
                                    <router-link :to="`/fengxiangm/0/${lag}`">
                                      <div
                                        class="lmore flex-row justify-center"
                                        style="
                                          margin-top: 0px;
                                          margin-bottom: 0px;
                                          width: 208px;
                                        "
                                      >
                                        <div
                                          class="lmorein flex-row justify-between"
                                        >
                                          <span class="text-group_123"
                                            >了解更多</span
                                          >

                                          <img
                                            referrerpolicy="no-referrer"
                                            src="https://cdn.moheweb.com/vdb/lweb/imgs/bj.png"
                                            class="image_186 bjt"
                                          />

                                          <img
                                            referrerpolicy="no-referrer"
                                            src="https://cdn.moheweb.com/vdb/lweb/imgs/wj.png"
                                            class="image_186 wjt"
                                            style="display: none"
                                          />
                                        </div>
                                      </div>
                                    </router-link>
                                  </div>
                                </div>
                              </div>

                              <!-- <div class="swiper2bardiv">
                                <span class="sc2index sc2startindex">01</span>
                                <div class="sc2bardiv">
                                  <div class="sc2bar"></div>
                                </div>
                                <span class="sc2index sc2finalindex">{{
                                  lslist.length < 10
                                    ? `0${lslist.length}`
                                    : lslist.length
                                }}</span>
                              </div> -->
                            </div>

                            <div class="lslist">
                              <template v-for="(item, index) in lslist">
                                <div
                                  class="lsitem"
                                  :key="index"
                                  @click="
                                    () => {
                                      gotourl('fengxiangm', item.kind);
                                    }
                                  "
                                >
                                  <div class="micon"></div>
                                  <div
                                    class="g7itemtext"
                                    v-html="item.des"
                                  ></div>
                                  <div class="g7iteminfo">
                                    <div
                                      class="g7itemtitle"
                                      v-html="item.title"
                                    ></div>
                                    <!-- <div class="g7itemdes" v-html="item.title2"></div> -->
                                    <img
                                      referrerpolicy="no-referrer"
                                      src="https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPnga3a5b20e0324082a486419c2b193dbce68f3a2cd2b92053dcbe69c15171ccc1e"
                                      class="image_jt"
                                    />
                                    <img
                                      referrerpolicy="no-referrer"
                                      src="https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPng4e043656295421bba5a24fd6b62f055c22039be9e721c037df2d8ce3f02df8ed"
                                      class="image_jtf"
                                    />
                                  </div>
                                  <div
                                    class="bimg"
                                    :style="`background-image: url(${item.imgurl});`"
                                  ></div>
                                </div>
                              </template>
                            </div>

                            <!-- <div class="swiper sc2">
                              <div class="swiper-wrapper">

                                <template v-for="(item, index) in lslist">
                                  <div :class="`swiper-slide`" :key="index">
                                    <div
                                      class="g7item flex-col"
                                      style="
                                        background-color: rgba(
                                          198,
                                          198,
                                          198,
                                          1
                                        );
                                      "
                                    >
                                      <div class="micon"></div>
                                      <div
                                        class="g7itemtext"
                                        v-html="item.des"
                                      ></div>
                                      <div class="g7iteminfo">
                                        <div
                                          class="g7itemtitle"
                                          v-html="item.title"
                                        ></div>
                                        <div
                                          class="g7itemdes"
                                          v-html="item.title2"
                                        ></div>
                                        <img
                                          referrerpolicy="no-referrer"
                                          src="https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPnga3a5b20e0324082a486419c2b193dbce68f3a2cd2b92053dcbe69c15171ccc1e"
                                          class="image_jt"
                                        />
                                        <img
                                          referrerpolicy="no-referrer"
                                          src="https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPng4e043656295421bba5a24fd6b62f055c22039be9e721c037df2d8ce3f02df8ed"
                                          class="image_jtf"
                                        />
                                      </div>
                                      <div
                                        class="bimg"
                                        :style="`background-image: url(${item.imgurl});`"
                                      ></div>
                                    </div>
                                  </div>
                                </template>
                                
                              </div>
                            </div> -->
                          </div>
                        </div>

                        <div class="backimg"></div>
                      </div>
                    </div>
                  </div>

                  <div :class="`swiper-slide ssm ssm7`">
                    <footer-b
                      :kind="`index`"
                      :g8top="g8top"
                      :g8left="g8left"
                      :lag="lag"
                    ></footer-b>
                  </div>
                </div>
              </div>
            </div>
            <div class="zzp">
              <img
                class="loadlogo"
                referrerpolicy="no-referrer"
                src="../assets/limgs/logo.png"
              />
              <img class="loading" src="../assets/limgs/loading.png" />
              <div class="backimg"></div>
            </div>

            <div class="showlogodiv" v-if="slshow">
              <div
                class="slclose"
                @click="
                  () => {
                    slclose();
                  }
                "
              ></div>
              <div class="sldiv">
                <div class="ldiv">
                  <div class="logoimg">
                    <img :src="baseUrl + flist11[logoindex].imgurl" />
                  </div>
                  <div class="logoinfo">
                    <div
                      class="logotitle"
                      v-html="flist11[logoindex].title"
                    ></div>
                    <div class="logodes" v-html="flist11[logoindex].des"></div>
                  </div>
                </div>
                <div class="ldiv2">
                  <div class="title">相关话题</div>
                  <div class="line"></div>
                  <div class="list">
                    <template
                      v-for="(item, index) in flist11[logoindex].mtipary"
                    >
                      <div class="item" :key="index">
                        <div class="t">
                          <a
                            :href="item.link"
                            target="_blank"
                            style="color: #000"
                            >{{ item.tip }}</a
                          >
                        </div>

                        <a
                          :href="item.link"
                          target="_blank"
                          style="color: #000"
                        >
                          <img
                            class="jt"
                            referrerpolicy="no-referrer"
                            src="https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPng293fdf47784f5f9695e686f2d9fce66c2a06c00689ebab17507b1a05e2617c89"
                          />
                        </a>
                      </div>
                    </template>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </a-layout-content>
      </a-layout>
    </a-layout>
  </div>
</template>

<script>
import {
  fetchClass,
  fetchCase,
  fetchFriend,
  fetchBase,
  fetchAwards,
} from "../api/index";
import TopB from "../components/TopB.vue";
import FooterB from "../components/FooterB.vue";
// const Base64 = require('js-base64').Base64
import Swiper, {
  FreeMode,
  Autoplay,
  Effect,
  Pagination,
  EffectFade,
} from "swiper";
import "swiper/swiper-bundle.css";
Swiper.use([FreeMode, Autoplay, Effect, Pagination, EffectFade]);
import global from "../common";
import $ from "jquery";
export default {
  name: "index",
  components: {
    TopB,
    FooterB,
  },
  props: {
    msg: String,
  },
  data() {
    return {
      bkind: "0",
      bmshow: 0,
      bmstate: false,
      bmid: "0",

      loadfetch40: false,
      loadfetch31: false,
      loadfetch33: false,
      loadfetch11: false,
      loadfetch90: false,
      loadfetch91: false,

      clist90: [],
      clist91: [],
      flist10: [],
      flist11: [],
      clist20: [],
      clist31: [],
      clist33: [],
      data33: null,
      clist40: [],

      logoindex: "0",
      slshow: false,

      lslist: [
        {
          title: "FM.看看世界",
          title2: "出行攻略",
          des: "趣野带你看世界-我们提供新鲜户外资讯，精选音乐/电影/书籍 等兴趣清单，还有各类好物/集合店指南，更多户外生活方式等你来发现！",
          id: "0",
          kind: "00",
          imgurl: "https://cdn.moheweb.com/vdb/lweb/imgs/back00.png",
        },
        {
          title: "趣野故事会",
          title2: "趣野故事会",
          des: "你也喜欢看故事么？此栏目应该很适合你-这里集合户外、任务、品牌故事",
          id: "0",
          kind: "01",
          imgurl: "https://cdn.moheweb.com/vdb/lweb/imgs/back01.png",
        },
        {
          title: "趣野视角",
          title2: "趣野视角",
          des: "带着趣野态度，从原创视角出发，给你带来系列精彩内容：可持续、出行方式、趣路亚、运动旅行、2024奥运会、中国户外文化",
          id: "0",
          kind: "02",
          imgurl: "https://cdn.moheweb.com/vdb/lweb/imgs/back02.png",
        },
      ],

      swiperlist: [
        {
          url: "https://cdn.moheweb.com/vdb/lweb/imgs/back0.png",
          title: "DARE<br />TO AIR",
          title2: "户外生活领跑者",
          des: "我们崇尚热爱户外生活的态度<br />注重人与自然之间的和谐互动<br />鼓励所有人勇于突破自我边界<br />探索属于自己的户外生活方式",
        },
        {
          url: "https://cdn.moheweb.com/vdb/lweb/imgs/back1.png",
          title: "DARE<br />TO AIR1",
          title2: "户外生活领跑者",
          des: "我们崇尚热爱户外生活的态度<br />注重人与自然之间的和谐互动<br />鼓励所有人勇于突破自我边界<br />探索属于自己的户外生活方式",
        },
        {
          url: "https://cdn.moheweb.com/vdb/lweb/imgs/back0.png",
          title: "DARE<br />TO AIR2",
          title2: "户外生活领跑者",
          des: "我们崇尚热爱户外生活的态度<br />注重人与自然之间的和谐互动<br />鼓励所有人勇于突破自我边界<br />探索属于自己的户外生活方式",
        },
        {
          url: "https://cdn.moheweb.com/vdb/lweb/imgs/back1.png",
          title: "DARE<br />TO AIR3",
          title2: "户外生活领跑者",
          des: "我们崇尚热爱户外生活的态度<br />注重人与自然之间的和谐互动<br />鼓励所有人勇于突破自我边界<br />探索属于自己的户外生活方式",
        },
      ],
      swiper0backimg: "",

      mdselectIndex: 0,
      splist: [
        {
          title: "期待好物",
          des: "以生活方式的需求出发，从轻户外装备到出勤工具，AllFields精选国内外符合潮流美学的户外品牌，对功能、材质、颜值、价格、口碑等方面进行评估和甄选，让你轻松「闭眼」购入。",
          price: "0.00",
          id: "0",
          url: "https://cdn.moheweb.com/vdb/lweb/imgs/ppimg0.png",
        },
        {
          title: "期待好物",
          des: "以生活方式的需求出发，从轻户外装备到出勤工具，AllFields精选国内外符合潮流美学的户外品牌，对功能、材质、颜值、价格、口碑等方面进行评估和甄选，让你轻松「闭眼」购入。",
          price: "0.00",
          id: "0",
          url: "https://cdn.moheweb.com/vdb/lweb/imgs/ppimg1.png",
        },
        {
          title: "期待好物",
          des: "以生活方式的需求出发，从轻户外装备到出勤工具，AllFields精选国内外符合潮流美学的户外品牌，对功能、材质、颜值、价格、口碑等方面进行评估和甄选，让你轻松「闭眼」购入。",
          price: "0.00",
          id: "0",
          url: "https://cdn.moheweb.com/vdb/lweb/imgs/ppimg2.png",
        },
      ],
      zlist: [
        {
          title: "趣野无限户外生活特展即将开幕",
          des: "趣野无限户外生活特展即将开幕",
          id: 0,
          url: "https://cdn.moheweb.com/vdb/lweb/imgs/z1.png",
        },
        {
          title: "趣野无限户外生活特展即将开幕",
          des: "趣野无限户外生活特展即将开幕",
          id: 0,
          url: "https://cdn.moheweb.com/vdb/lweb/imgs/z1.png",
        },
        {
          title: "趣野无限户外生活特展即将开幕",
          des: "趣野无限户外生活特展即将开幕",
          id: 0,
          url: "https://cdn.moheweb.com/vdb/lweb/imgs/z1.png",
        },
        {
          title: "趣野无限户外生活特展即将开幕",
          des: "趣野无限户外生活特展即将开幕",
          id: 0,
          url: "https://cdn.moheweb.com/vdb/lweb/imgs/z1.png",
        },
        {
          title: "趣野无限户外生活特展即将开幕",
          des: "趣野无限户外生活特展即将开幕",
          id: 0,
          url: "https://cdn.moheweb.com/vdb/lweb/imgs/z1.png",
        },
        {
          title: "趣野无限户外生活特展即将开幕",
          des: "趣野无限户外生活特展即将开幕",
          id: 0,
          url: "https://cdn.moheweb.com/vdb/lweb/imgs/z1.png",
        },
      ],
      pageHeight: 0,
      tbanimate: "down",
      winHeight: 0,
      topbarhide: 0,
      lag: "",
      w750: 0,
      m_group_2_height: 0,
      basedata: [],
      frienddata: [],
      frienddata2: [],
      ppswiperindex: 0,
      nopplistshow: 0,
      baseUrl: global.baseUrl,
      thisclass1: 68,
      thisclass2: "",

      classlist: [],

      class2list: [],

      swiper: null,

      swiper0: null,

      swiper1: null,

      swiper2: null,

      swiper3: null,

      lswiper0: null,

      lswiper1: null,

      lswiper2: null,

      lswiper3: null,

      lswiper4: null,

      selectIndex: 0,

      barclass: "closethis",
      pageWidth: 1920,
      topbarOpacity: 0,
      gtitle: "整合内容营销&nbsp;+&nbsp;智能数据平台&nbsp;+&nbsp;奢侈品营销",
      gdesc:
        "IPmotion（上海派志）作为一类以独特创意DNA驱动的公司，而区别于一般的“广告创意公司”。<br />成立十多年来，IPmotion（上海派志）在人工智能、IT电子、医药、汽车等垂直领域积累了丰富的经验案例，并助力企业、品牌的营销转型和数字转型的全过程。为全球500强与国内行业领先品牌，如：智能科技类企业：世界人工智能大会、世界制造业大会、华为、远景集团、医药类企业：阿斯利康、基石药业、BD医疗、奢侈品类企业：香奈儿、MHD、帕玛强尼等提供全案创意营销代理服务和线下解决方案。",
      mkinds: [],
      ppindex: -1,
      pplist: [],
      bselectindex: 1,
      blinks: [
        { title: "趣野风向", url: "", class: "jianjie" },
        { title: "无限好物", url: "", class: "huoban" },
        { title: "趣野展会", url: "", class: "fuwuneirong" },
        { title: "趣野CLUB", url: "", class: "anli" },
        { title: "趣野论坛", url: "", class: "lianxiwomen" },
        { title: "关于我们", url: "ai", class: "" },
      ],
      sectionindex: 0,
      sections: [],
      chsections: [
        {
          img: "i-logo-0.png",
          title: "展览展厅&amp;活动",
          desc: "提供前沿的创意理念，及线下制作的一体化服务",
          tip: ["临时展厅", "常设展厅", "多媒体互动", "交互内容", "线下活动"],
        },
        {
          img: "i-logo-1.png",
          title: "智能平台&amp;多媒体互动",
          desc: "提供服务与解决方案的智能科技应用平台",
          tip: ["数字营销", "社会化传播", "TVC视频", "宣传片"],
        },
        {
          img: "i-logo-2.png",
          title: "智能平台&amp;多媒体互动",
          desc: "提供服务与解决方案的智能科技应用平台",
          tip: ["智能会务系统", "智能云展", "数字孪生", "智能机器人"],
        },
        {
          img: "i-logo-3.png",
          title: "奢侈品营销",
          desc: "结合线下制作、运营于一体的互动体验",
          tip: ["促销活动", "体验营销"],
        },
      ],
      ensections: [
        {
          img: "i-logo-0.png",
          title: "Exhibition & Event",
          desc: "Provide cutting-edge creative concepts and integrated services for offline production",
          tip: ["Exhibition", "Muti-Media", "Interactive", "Physical Event"],
        },
        {
          img: "i-logo-1.png",
          title: "Digital Marketing & <br />Video Creation",
          desc: "Covering both online and offline,Integrate omnichannel promotion and dissemination",
          tip: [
            "Digital marketing",
            "Social Communication",
            "TVC Video",
            "Promotional video",
          ],
        },
        {
          img: "i-logo-2.png",
          title: "Intelligence Platform & <br />Multi Interactive",
          desc: "An intelligent technology application platform that provides services and solutions",
          tip: [
            "Intelligent conference system",
            "Intelligent Virtual Event",
            "Digital twin",
            "intelligent robot",
          ],
        },
        {
          img: "i-logo-3.png",
          title: "Luxury",
          desc: "Interactive experience combining offline production and operation",
          tip: ["Promotional activities", "Experience Marketing"],
        },
      ],
      data: [],
      dataloading: 0,
      pageSize: 12,
      query: {
        class1: [],
        class2: [],
      },
      alive: true,
      adata: [],
      awardsindex: 0,

      opy1: 0,
      opy2: 0,
      opy3: 0,
      opy4: 0,
      opy5: 0,
      opy6: 0,
      opy7: 0,
      opy8: 0,

      group1h: 0,
      group2h: 0,
      group3h: 0,
      group4h: 0,
      group5h: 0,
      group6h: 0,
      group7h: 0,

      tb: true,
      masktop: 0,
      masksize: 0,
      g8top: 0,
      g8left: 0,

      mselectIndex: 0,

      initHeight: 0,
    };
  },

  activated() {
    const that = this;
    // let scrollvalue = global.getIndexscroll();
    // if (scrollvalue > 0) {
    //   $(".main").scrollTop(scrollvalue);
    // }

    // if (scrollvalue > 400) {
    //   this.topbarOpacity = (scrollvalue - 400) / 200;
    //   if (this.topbarOpacity > 1) {
    //     this.topbarOpacity = 1;
    //   }
    // } else {
    //   this.topbarOpacity = 0;
    // }

    // setTimeout(() => {
    //   let classtext = global.getIndexDclass();
    //   // console.log("classtext:", classtext);
    //   if (classtext != "") {
    //     that.goDiv(classtext, "");
    //   }
    //   // let video0 = document.getElementById("video0");
    //   // video0.play();
    // }, 1000);
    // this.alive = true;
    // // this.handleScroll;

    setTimeout(() => {
      that.initHeight = window.innerHeight;
      that.resizepage();
      if (that.swiper) {
        that.swiper.update();
        that.lswiper0.update();
        that.lswiper1.update();
        that.lswiper2.update();
      }
    }, 100);
  },
  deactivated() {
    console.log("deactivated:");
    this.alive = false;
  },

  mounted() {
    // var scrollSpeed = .1; // 滚动速度，默认值为1
    // var scrollDistance = 200; // 滚动距离，默认值为200像素

    // // 在滚动事件中修改滚动速度和距离
    // window.addEventListener(
    //   "wheel",
    //   function (e) {
    //     console.log(e.deltaY);
    //     // 如果滚轮向上滚动，增加滚动距离
    //     if (e.deltaY < 0) {
    //       scrollDistance /= 200; // 将滚动距离乘以2
    //       scrollSpeed /= 100; // 将滚动速度增加50%
    //     }
    //     // 如果滚轮向下滚动，减小滚动距离
    //     else {
    //       scrollDistance /= 200; // 将滚动距离除以2
    //       scrollSpeed /= 1.5; // 将滚动速度减少50%
    //     }

    //     // 防止滚动距离或速度小于最小值
    //     if (scrollDistance < 100) {
    //       scrollDistance = 100; // 最小滚动距离为100像素
    //     }
    //     if (scrollSpeed < 0.5) {
    //       scrollSpeed = 0.5; // 最小滚动速度为0.5
    //     }

    //     // 修改滚动速度和距离
    //     var deltaY = e.deltaY * scrollSpeed; // 计算滚动速度
    //     window.scrollBy(0, deltaY); // 滚动页面
    //   },
    //   false
    // );

    let that = this;

    let lag = this.$route.params.lag;

    if (lag) {
      this.lag = lag;
    }

    console.log("this.lag:", this.lag);

    if (this.lag == "en") {
      this.sections = this.ensections;
    } else {
      this.sections = this.chsections;
    }
    global.setIndexDclass("");
    global.setIndexscroll(0);
    // that.handleScroll;

    that.initHeight = window.innerHeight;
    that.resizepage();
    // this.$el.addEventListener("scroll", that.handleScroll, true);
    // window.addEventListener("resize", that.handleResize, true);
    window.onresize = () => {
      that.handleResize();
    };

    setTimeout(() => {
      that.initHeight = window.innerHeight;
      that.resizepage();
    }, 500);

    that.myswiper1();
    that.myswiper2();
    that.myswiper3();
    setTimeout(() => {
      $(document).on("click", ".closesw", function () {
        $(".showppdiv").fadeTo(300, 0, () => {
          $(".showppdiv").css({ left: "-100%" });
        });
      });
      $(document).on("click", ".closesw3", function () {
        $(".showawardsdiv").fadeTo(300, 0, () => {
          $(".showawardsdiv").css({ left: "-100%" });
        });
      });
    }, 1000);

    that.fetch40();
    that.fetch31();
    that.fetch33();
    that.fetch10();
    that.fetch11();
    that.fetch20();
    that.fetch90();
    that.fetch91();

    that.myswiper();

    that.resetswiper();

    // fetchBase({}).then((res) => {
    //   that.loading = false;
    //   that.basedata = res.results;
    //   global.setbaseinfo(that.basedata);
    //   that.basedata = global.getbaseinfo();
    // });

    // that.getClassList();
    // that.fetch();
    // that.fetchf();
    that.fetchb();
    // that.fetcha();
  },

  beforeDestroy() {
    window.onresize = null;
    console.log("index beforeDestroy");
  },
  beforeCreate() {
    let that = this;
    // that.resizepage();
  },
  methods: {
    takeprice(price) {
      let pstr = "";
      if (price.split(".").length > 1) {
        if (price.split(".")[1].length < 2) {
          pstr = "0";
          return String(price) + pstr;
        }
      } else {
        return String(price) + ".00";
      }
    },

    resetswiper() {
      const that = this;
      if (
        this.loadfetch40 &&
        this.loadfetch31 &&
        this.loadfetch33 &&
        this.loadfetch11 &&
        this.loadfetch90 &&
        this.loadfetch91
      ) {
        that.resizeswiper();
      } else {
        setTimeout(() => {
          that.resetswiper();
        }, 500);
      }
    },

    // bmopen(id) {
    //   let that = this;
    //   // checkMember({}).then((res) => {
    //   //   console.log("checkMember:", res);
    //   //   let message = res.results.message;

    //   //   if (message == "0") {
    //   //     alert("您还没有登录，请先登录！");
    //   //   } else if (message == "1") {
    //   //     this.bmshow = 1;
    //   //     this.bmstate = true;
    //   //     this.bmid = id;
    //   //     this.bkind = '0';
    //   //     console.log("this.bmshow:", this.bmshow);
    //   //   }
    //   // });

    //   this.bmshow = 1;
    //   this.bmstate = true;
    //   this.bmid = id;
    //   this.bkind = "0";
    //   console.log("this.bmshow:", this.bmshow);
    // },
    bmopen() {
      let that = this;
      // checkMember({}).then((res) => {
      //   console.log("checkMember:", res);
      //   let message = res.results.message;

      //   if (message == "0") {
      //     alert("您还没有登录，请先登录！");
      //   } else if (message == "1") {
      //     this.bmshow = 1;
      //     this.bmstate = true;
      //     this.bmid = id;
      //     this.bkind = '0';
      //     console.log("this.bmshow:", this.bmshow);
      //   }
      // });

      this.bmshow = 1;
      this.bmstate = true;
      this.bmid = "0";
      this.bkind = "0";
      console.log("this.bmshow:", this.bmshow);
    },
    bmclose(id) {
      console.log("bmclose");
      this.bmshow = 0;
      
      this.bmid = id;

      this.bmstate = false;

      console.log("this.bmshow:", this.bmshow);
    },

    godetall(link, title, kind, id) {
      // alert(title+link);
      if (link != "") {
        window.location.href = link;
      } else {
        if (title != "") {
          this.$router.push(`/mdetallm/${kind}/${id}/${this.lag}`);
        }
      }
    },

    slclose() {
      this.slshow = false;
    },
    setlogoindex(index) {
      this.logoindex = index;
      this.slshow = true;
    },

    gotourl(url, kind) {
      this.$router.push(`/${url}/${kind}`);
    },

    showdetall(kind, id) {
      console.log(kind, id);
      this.$router.push(`/mdetallm/${kind}/${id}/${this.lag}`);
    },

    swSlideTo(index, id) {
      // alert(this.w750);

      if (this.w750 == 0) {
      } else if (this.w750 == 1) {
        if (this.lag == "en") {
          this.$router.push(`/pdetallm/${d}/en`);
        } else {
          this.$router.push(`/pdetallm/${id}`);
        }
        return false;
      }
      let that = this;
      this.swiper.slideTo(index, 0);
      $(".showppdiv").css({ left: "0%" });
      $(".showppdiv").fadeTo(300, 1, () => {});
    },

    fetch10(
      params = { show: 1, hot: 1, kind: "10", pagesize: 999, query: this.query }
    ) {
      let that = this;
      fetchFriend(params).then((res) => {
        if (res.results.list) {
          that.flist10 = res.results.list;
        }
        console.log("flist10:", that.flist10);
        that.resizeswiper();
        that.loadfetch10 = true;
      });
    },
    fetch11(
      params = { show: 1, hot: 1, kind: "11", pagesize: 999, query: this.query }
    ) {
      let that = this;
      fetchFriend(params).then((res) => {
        if (res.results.list) {
          that.flist11 = res.results.list;
        }
        console.log("flist11:", that.flist11);
        that.resizeswiper();
        that.loadfetch11 = true;
      });
    },

    fetch90(
      params = { show: 1, hot: 1, kind: 90, pagesize: 99, query: { mobile: 1 } }
    ) {
      let that = this;
      fetchCase(params).then((res) => {
        if (res.results.list) {
          that.clist90 = res.results.list;
        }
        console.log("clist90:", that.clist90);
        that.myswiper0();

        setTimeout(() => {
          $(".zzp").fadeTo(1000, 0, function () {
            $(".zzp").hide();
          });
        }, 3000);
        that.loadfetch90 = true;
      });
    },

    fetch91(
      params = { show: 1, hot: 1, kind: 91, pagesize: 99, query: this.query }
    ) {
      let that = this;
      fetchCase(params).then((res) => {
        if (res.results.list) {
          that.clist91 = res.results.list;
        }
        console.log("clist91:", that.clist91);
        this.myswiper6();
        that.loadfetch91 = true;
      });
    },

    fetch40(
      params = { show: 1, hot: 1, kind: 40, pagesize: 3, query: this.query }
    ) {
      let that = this;
      fetchCase(params).then((res) => {
        if (res.results.list) {
          that.clist40 = res.results.list;
        }
        console.log("clist40:", that.clist40);
        that.resizeswiper();
        that.loadfetch40 = true;
      });
    },
    fetch31(
      params = { show: 1, hot: 1, kind: 31, pagesize: 6, query: this.query }
    ) {
      let that = this;
      fetchCase(params).then((res) => {
        if (res.results.list) {
          that.clist31 = res.results.list;
        }
        console.log("clist31:", that.clist31);
        that.resizeswiper();
        that.loadfetch31 = true;
      });
    },

    fetch33(
      params = { show: 1, hot: "", kind: 33, pagesize: 6, query: this.query }
    ) {
      let that = this;
      fetchCase(params).then((res) => {
        if (res.results.list) {
          that.clist33 = res.results.list;
          if (that.clist33.length > 0) {
            that.data33 = that.clist33[0];
          }
        }
        console.log("clist33:", that.clist33);
        that.resizeswiper();
        that.loadfetch33 = true;
      });
    },

    swiper1Prev() {
      this.swiper1.slidePrev(500);
    },
    swiper1Next() {
      this.swiper1.slideNext(500);
    },

    windowopen(url) {
      if (url != "") {
        window.open(url);
      }
    },
    fetch20(
      params = { show: 1, hot: 1, kind: "20", pagesize: 999, query: this.query }
    ) {
      let that = this;
      fetchCase(params).then((res) => {
        if (res.results.list) {
          that.clist20 = res.results.list;
        }
      });
    },
    fetch(
      params = { show: 1, hot: 1, pagesize: this.pageSize, query: this.query }
    ) {
      this.ppswiperindex = 0;
      let that = this;
      this.loading = true;

      fetchCase(params).then((res) => {
        // console.log("fetchCase:", res);
        const pagination = { ...that.pagination };
        pagination.total = parseInt(res.results.total);
        pagination.pageSize = that.pageSize;
        that.loading = false;

        if (res.results.list) {
          that.data = res.results.list;
          that.nopplistshow = 0;
        } else {
          that.nopplistshow = 1;
        }
        that.dataloading = 0;
        that.pagination = pagination;
        // console.log("data:", that.data);
        // setTimeout(() => {
        //   that.swiper.update();
        // }, 100);
      });
    },
    fetchf(params = { show: 1, pagesize: 1000 }) {
      let that = this;
      this.loading = true;

      fetchFriend(params).then((res) => {
        // console.log("fetchFriend:", res);
        const pagination = { ...that.pagination };
        pagination.total = parseInt(res.results.total);
        pagination.pageSize = that.pageSize;
        that.loading = false;

        that.frienddata = res.results.list;

        let frienddata2 = [];

        let lindex = 0;
        res.results.list.map((item, index) => {
          if (index % 4 == 0) {
            frienddata2[lindex] = [];
          }
          frienddata2[lindex].push(item);
          if (index % 4 == 3) {
            lindex++;
          }
        });
        that.frienddata2 = frienddata2;

        // console.log("frienddata:", that.frienddata);
        // console.log("frienddata2:", that.frienddata2);

        if (that.w750 == 0) {
          that.myswiper2();
        } else {
          that.myswiper2m();
        }
      });
    },
    fetcha(params = { show: 1, hot: 1, pagesize: this.pageSize }) {
      this.ppswiperindex = 0;
      let that = this;
      this.loading = true;

      fetchAwards(params).then((res) => {
        console.log("fetchAwards:", res);
        const pagination = { ...that.pagination };
        pagination.total = parseInt(res.results.total);
        pagination.pageSize = that.pageSize;

        if (res.results.list) {
          that.adata = res.results.list;
        } else {
        }
        that.pagination = pagination;
        // console.log("data:", that.data);
        // setTimeout(() => {
        //   that.swiper3.update();
        // }, 100);
      });
    },

    fetchb(params = {}) {
      let that = this;
      this.loading = true;

      fetchBase({}).then((res) => {
        that.loading = false;
        that.basedata = res.results;
        global.setbaseinfo(that.basedata);
        that.basedata = global.getbaseinfo();
      });

      // fetchBase({}).then((res) => {
      //   // console.log("fetchBase:", res.results);
      //   that.loading = false;
      //   that.basedata = res.results;

      //   that.blinks[0].title = res.results.t0;
      //   that.blinks[1].title = res.results.t1;
      //   that.blinks[2].title = res.results.t2;
      //   that.blinks[3].title = res.results.t3;
      //   that.blinks[4].title = res.results.t4;
      //   that.blinks[5].title = res.results.t5;

      //   if (that.lag == "en") {
      //     that.blinks[0].title = res.results.ent0;
      //     that.blinks[1].title = res.results.ent1;
      //     that.blinks[2].title = res.results.ent2;
      //     that.blinks[3].title = res.results.ent3;
      //     that.blinks[4].title = res.results.ent4;
      //     that.blinks[5].title = res.results.ent5;

      //     that.basedata.t0 = res.results.ent0;
      //     that.basedata.t1 = res.results.ent1;
      //     that.basedata.t2 = res.results.ent2;
      //     that.basedata.t3 = res.results.ent3;
      //     that.basedata.t4 = res.results.ent4;
      //     that.basedata.t45 = res.results.ent45;
      //     that.basedata.t5 = res.results.ent5;
      //     that.basedata.t6 = res.results.ent6;
      //     that.basedata.t7 = res.results.ent7;
      //     that.basedata.t8 = res.results.ent8;
      //     that.basedata.t9 = res.results.ent9;
      //     that.basedata.t10 = res.results.ent10;

      //     that.basedata.tel = res.results.entel;
      //     that.basedata.adr = res.results.enadr;
      //     that.basedata.email = res.results.enemail;
      //     that.basedata.mtitle = res.results.enmtitle;
      //     that.basedata.mcontent = res.results.enmcontent;
      //     that.basedata.aititle = res.results.enaititle;
      //     that.basedata.aicontent = res.results.enaicontent;
      //     that.basedata.aicontent0 = res.results.enaicontent0;
      //     that.basedata.aicontent1 = res.results.enaicontent1;
      //     that.basedata.aicontent2 = res.results.enaicontent2;
      //     that.basedata.aicontent3 = res.results.enaicontent3;
      //     that.basedata.aicontent4 = res.results.enaicontent4;
      //   }
      // });
    },
    selectclass2(id) {
      this.nopplistshow = 0;
      this.dataloading = 1;
      this.thisclass2 = id;
      // console.log(id);
      this.query = {
        class1: this.thisclass1,
        class2: [id],
      };
      this.data = [];
      setTimeout(() => {
        this.fetch();
      }, 500);
    },
    getClassList() {
      const that = this;
      fetchClass().then((res) => {
        // console.log(
        //   "%cfetchClass",
        //   "color: green;font-size: 14px;font-weight: bold;",
        //   res
        // );
        that.classlist = res.results.list;

        // console.log("classlist:", that.classlist);

        that.class2list = this.classlist.filter((it) => {
          return this.thisclass1 == it.id;
        })[0]["list"];

        // console.log("class2list:", that.class2list);

        let mkinds = [];

        that.class2list.map((item) => {
          let cname = item.cname;
          if (that.lag == "en") {
            cname = item.encname;
          }
          mkinds.push({ name: cname, id: item.id });
        });

        that.mkinds = mkinds;

        console.log("mkinds:", this.mkinds);
      });
    },

    swSlideTo(index, id) {
      // alert(this.w750);

      if (this.w750 == 0) {
      } else if (this.w750 == 1) {
        if (this.lag == "en") {
          this.$router.push(`/pdetall/${d}/en`);
        } else {
          this.$router.push(`/pdetall/${id}`);
        }
        return false;
      }
      let that = this;
      this.swiper.slideTo(index, 0);
      $(".showppdiv").css({ left: "0%" });
      $(".showppdiv").fadeTo(300, 1, () => {});
    },

    sw3SlideTo(index, id) {
      console.log(index, id);

      if (this.w750 == 0) {
      } else if (this.w750 == 1) {
        if (this.lag == "en") {
          this.$router.push(`/adetall/${d}/en`);
        } else {
          this.$router.push(`/adetall/${id}`);
        }
        return false;
      }
      let that = this;
      this.swiper3.slideTo(index, 0);
      $(".showawardsdiv").css({ left: "0%" });
      $(".showawardsdiv").fadeTo(300, 1, () => {});
    },
    gowebsite(url) {
      window.open(url);
    },
    myswiper() {
      let that = this;

      setTimeout(() => {
        this.swiper = new Swiper(".sc", {
          // duration: 1000,
          // effect: "fade",
          direction: "vertical",
          // freeMode: true,
          // freeMode: {
          //   momentum: true,
          // },
          mousewheel: true,
          resistanceRatio: 0,
          observer: true, //修改swiper自己或子元素时，自动初始化swiper
          observeParents: true, //修改swiper的父元素时，自动初始化swiper
          observeSlideChildren: true,
          on: {
            transitionStart: function (swiper) {
              // console.log("transitionStart");

              console.log("transitionStart", this.activeIndex);
              $(`.ssm`).removeClass("smmanimate");
              console.log(`.ssm${this.activeIndex}`);
              $(`.ssm${this.activeIndex}`).addClass("smmanimate");
            },

            transitionEnd: function () {
              that.mselectIndex = this.activeIndex;
              if (that.mselectIndex > 0) {
                that.tb = false;
              } else {
                that.tb = true;
              }
            },
            init: function (swiper) {
              this.emit("transitionEnd");
            },
          },
        });

        console.log("this.swiper:", this.swiper);
        this.lswiper0 = new Swiper(".lsc0", {
          //子swiper
          direction: "vertical",
          nested: true,
          resistanceRatio: 0,
          slidesPerView: "auto",
          // freeMode: true,

          freeMode: {
            momentumVelocityRatio: 1,
            momentum: true,
            enabled: true,
          },

          // observer: true, //修改swiper自己或子元素时，自动初始化swiper
          // observeParents: true, //修改swiper的父元素时，自动初始化swiper
          // observeSlideChildren: true,
          // scrollbar: {
          //   el: ".swiper-scrollbar",
          // },
        });
        this.lswiper1 = new Swiper(".lsc1", {
          //子swiper
          direction: "vertical",
          nested: true,
          resistanceRatio: 0,
          slidesPerView: "auto",
          // freeMode: true,
          freeMode: {
            momentumVelocityRatio: 1,
            momentum: true,
            enabled: true,
          },
          // observer: true, //修改swiper自己或子元素时，自动初始化swiper
          // observeParents: true, //修改swiper的父元素时，自动初始化swiper
          // observeSlideChildren: true,
          // scrollbar: {
          //   el: ".swiper-scrollbar",
          // },
        });

        this.lswiper2 = new Swiper(".lsc2", {
          //子swiper
          direction: "vertical",
          nested: true,
          resistanceRatio: 0,
          slidesPerView: "auto",
          freeMode: {
            momentumVelocityRatio: 1,
            momentum: true,
            enabled: true,
          },
          // observer: true, //修改swiper自己或子元素时，自动初始化swiper
          // observeParents: true, //修改swiper的父元素时，自动初始化swiper
          // observeSlideChildren: true,
          // scrollbar: {
          //   el: ".swiper-scrollbar",
          // },
        });
      }, 10);
    },

    resizeswiper() {
      this.swiper.update();
      this.lswiper0.update();
      this.lswiper1.update();
      this.lswiper2.update();
    },

    myswiper0() {
      let that = this;
      $(".sc0zb").show();
      that.swiper0backimg =
        that.baseUrl + that.clist90[that.clist90.length - 1].imgurl;
      setTimeout(() => {
        that.swiper0backimg = that.baseUrl + that.clist90[0].imgurl;
        $(".ss0 .mask").removeClass("mkmaskshow");
        $(".sc0zb").hide();
      }, 2000);

      setTimeout(() => {
        this.swiper0 = new Swiper(".sc0", {
          effect: "fade",
          speed: 0,
          noSwiping: true,
          autoplay: {
            delay: 6000,
            disableOnInteraction: false,
          },
          observer: true, //修改swiper自己或子元素时，自动初始化swiper
          observeParents: true, //修改swiper的父元素时，自动初始化swiper
          observeSlideChildren: true,
          on: {
            // slideChangeTransitionStart: function () {
            //   console.log("transitionStart",this.activeIndex);

            //   $(".ss" + this.activeIndex + " .mk0").addClass("mkmaskshow");
            // },
            slideChange: function () {
              $(".sc0zb").show();

              // console.log("transitionEnd:", this.activeIndex);

              $(".ss" + this.activeIndex + " .mk0").addClass("mkmaskshow");

              that.mdselectIndex = this.activeIndex;
              let thisimgurl = that.clist90[this.activeIndex].imgurl;
              setTimeout(() => {
                $(".sc0 .mask").removeClass("mkmaskshow");
                that.swiper0backimg = that.baseUrl + thisimgurl;
                console.log("that.swiper0backimg:", that.swiper0backimg);
                $(".sc0zb").hide();
              }, 2000);
            },
          },
        });

        $(".zzp").fadeTo(1000, 0, function () {
          $(".zzp").hide();
        });
      }, 10);
    },

    myswiper1() {
      let that = this;

      setTimeout(() => {
        this.swiper1 = new Swiper(".sc1", {
          // slidesPerView: 3,
          // slidesPerView: 2,
          // loop: true,
          centeredSlides: true,
          spaceBetween: 0,
          grabCursor: true,
          pagination: {
            el: ".swiper-pagination",
            clickable: true,
          },
          observer: true, //修改swiper自己或子元素时，自动初始化swiper
          observeParents: true, //修改swiper的父元素时，自动初始化swiper
          observeSlideChildren: true,
          // duration: 1000,
          // pagination: {
          //   // el: '.swiper-pagination',
          // },
          // navigation: {
          //   nextEl: ".swiper-button-next",
          //   prevEl: ".swiper-button-prev",
          // },
          on: {
            transitionEnd: function () {
              console.log("transitionEnd:", this.activeIndex);
              that.selectIndex = this.activeIndex;
              $(`.sc1 .swiper-slide`).removeClass("ssselected");
              $(`.ss${this.activeIndex}`).addClass("ssselected");
            },
            init: function (swiper) {
              // this.emit("transitionEnd"); //在初始化时触发一次transitionEnd事件，需要先设置transitionEnd
            },
          },
        });
      }, 10);
    },
    myswiper3() {
      let that = this;

      // setTimeout(() => {
      //   that.swiper3 = new Swiper(".sc3", {
      //     duration: 1000,
      //     pagination: {
      //       // el: '.swiper-pagination',
      //     },
      //     navigation: {
      //       nextEl: ".swiper-button-next",
      //       prevEl: ".swiper-button-prev",
      //     },
      //     on: {
      //       transitionEnd: function () {
      //         that.selectIndex = this.activeIndex;
      //         // console.log(that.selectIndex);
      //       },
      //       init: function (swiper) {
      //         this.emit("transitionEnd"); //在初始化时触发一次transitionEnd事件，需要先设置transitionEnd
      //       },
      //     },
      //   });
      // }, 10);
    },

    myswiper6() {
      let that = this;

      setTimeout(() => {
        this.swiper6 = new Swiper(".sc6", {
          // autoHeight: true,
          observer: true, //修改swiper自己或子元素时，自动初始化swiper
          observeParents: true, //修改swiper的父元素时，自动初始化swiper
          observeSlideChildren: true,

          pagination: {
            el: ".sp6",
            clickable: true,
          },
        });
      }, 500);
    },

    myswiper2() {
      let that = this;

      setTimeout(() => {
        this.swiper2 = new Swiper(".sc2", {
          slidesPerView: 3,
          // slidesPerView: 2,
          loop: true,
          centeredSlides: true,
          spaceBetween: 0,
          grabCursor: true,
          observer: true, //修改swiper自己或子元素时，自动初始化swiper
          observeParents: true, //修改swiper的父元素时，自动初始化swiper
          observeSlideChildren: true,
          // pagination: {
          //   el: ".swiper-pagination",
          //   clickable: true,
          // },

          on: {
            transitionEnd: function () {
              // that.selectIndex = this.activeIndex;

              // console.log("this", this);

              // console.log("this.selectIndex:", this.realIndex);
              let w =
                parseInt(
                  ((parseInt(this.realIndex) + 1) / that.lslist.length) * 1000
                ) / 10;
              // console.log("www:", w);
              $(".sc2bar").css({ width: w + "%" });
            },
            init: function (swiper) {
              let w = parseInt((1 / that.lslist.length) * 1000) / 10;
              $(".sc2bar").css({ width: w + "%" });
            },
          },
        });
      }, 10);
    },

    myswiper2m() {
      let that = this;
    },

    handleResize() {
      this.resizepage();
    },

    resizeViewport() {
      console.log("resizeViewport m");
      let that = this;
      let bl = that.initHeight / window.innerWidth;
      if (bl > 1.2) {
        this.sectionindex = 0;
        this.m_group_2_height = this.pageHeight;
        this.w750 = 1;

        if (bl > 1.5) {
          $("meta[name=viewport]").attr(
            "content",
            "width=750,user-scalable=no"
          );
        } else {
          $("meta[name=viewport]").attr(
            "content",
            "width=1280,user-scalable=no"
          );
        }
      } else {
        $("meta[name=viewport]").attr("content", "width=1280,user-scalable=no");
        this.m_group_2_height = 0;
        this.w750 = 0;
        this.$router.replace(`/index/${this.lag}`);
        // window.location.href = `#/index/${this.lag}`;
        setTimeout(() => {
          window.location.reload();
        }, 500);
      }
    },
    resizepage() {
      const that = this;
      console.log("resizepage");

      this.pageWidth = window.innerWidth;
      let pageHeight = that.initHeight;

      this.pageHeight = pageHeight;

      $(".windowheight").css({ height: `${pageHeight}px` });

      // if (window.innerWidth / window.innerHeight <= 1) {
      //   this.sectionindex = 0;
      //   this.m_group_2_height = pageHeight;
      //   this.w750 = 1;
      //   // this.$router.push(`/indexm/${this.lag}`);
      //   window.location.href = `#/indexm/${this.lag}`;
      // } else {
      //   this.m_group_2_height = 0;
      //   this.w750 = 0;
      // }

      this.resizeViewport();

      // console.log("resize", this.pageWidth);
      // setTimeout(() => {
      $("html").css({ fontSize: this.pageWidth / 192 });
      // }, 500);

      // setTimeout(() => {
      //   that.resizepage();
      // },100);

      // setTimeout(() => {
      //   that.swiper2.update();
      // }, 1000);

      // if (that.w750 == 0) {
      //   // if (that.swiper2) {
      //   //   that.swiper2.destroy(false, true);
      //   // }
      //   that.myswiper2();
      // } else {
      //   if (that.swiper2) {
      //     that.swiper2.destroy(false, true);
      //   }
      //   that.myswiper2m();
      // }
    },
    handleScroll(e) {
      // console.log("handleScroll");

      if (this.alive) {
        const winHeight =
          e.target.scrollTop || document.documentElement.scrollTop;
        // console.log("e.target.scrollTop:",e.target.scrollTop);
        global.setIndexscroll(winHeight);

        // console.log("Scroll:", winHeight);

        let group1h = $(".group_1").height();
        let group2h = $(".group_2").height();
        let group3h = $(".group_3").height();
        let group4h = $(".group_4").height();
        let group5h = $(".group_5").height();
        let group6h = $(".group_6").height();
        let group7h = $(".group_7").height();
        this.group1h = group1h;
        this.group2h = group2h;
        this.group3h = group3h;
        this.group4h = group4h;
        this.group5h = group5h;
        this.group6h = group6h;
        this.group7h = group7h;

        this.tb = true;

        // if (
        //   this.winHeight >
        //   this.pageHeight +
        //     this.pageHeight +
        //     this.pageHeight +
        //     group2h +
        //     group4h +
        //     group6h +
        //     2000 +
        //     2000
        // ) {
        //   console.log("down");
        //   this.tbanimate = "up";
        //   // $(".topbar").stop(true).animate({ top: "-140px" }, 100);
        //   $(".topbar").removeClass("downtopbar");
        //   $(".topbar").addClass("uptopbar");
        // }

        // if (winHeight > 120) {
        //   if (this.winHeight > winHeight && this.tbanimate != "down") {
        //     this.tbanimate = "down";
        //     // $(".topbar").stop(true).animate({ top: "0px" }, 100);
        //     $(".topbar").removeClass("uptopbar");
        //     $(".topbar").addClass("downtopbar");
        //   } else if (this.winHeight < winHeight && this.tbanimate != "up") {
        //     this.tbanimate = "up";
        //     // $(".topbar").stop(true).animate({ top: "-140px" }, 100);
        //     $(".topbar").removeClass("downtopbar");
        //     $(".topbar").addClass("uptopbar");
        //   }
        // }

        // this.winHeight = winHeight;
        // if (winHeight > 400) {
        //   this.topbarOpacity = (winHeight - 400) / 200;
        //   if (this.topbarOpacity > 1) {
        //     this.topbarOpacity = 1;
        //   }
        // } else {
        //   this.topbarOpacity = 0;
        // }

        // $(".page").css({
        //   height:
        //     this.pageHeight +
        //     group1h +
        //     group2h +
        //     group3h +
        //     group4h +
        //     group5h +
        //     group6h +
        //     group7h +
        //     4000 +
        //     4000 +
        //     1800 +
        //     "px",
        // });

        // console.log(winHeight);
      }
    },
    changelag(lag) {
      if (lag == "en") {
        this.$router.push("index/en");
        setTimeout(() => {
          location.reload();
        }, 100);
      } else {
        this.$router.push("/index");
        setTimeout(() => {
          location.reload();
        }, 100);
      }
    },
    goDiv(classtext, url) {
      this.barclass = "closethis";
      if (url != "") {
        global.setIndexscroll(0);
        if (this.lag == "en") {
          url = `/${url}/en`;
        }
        this.$router.push(url);
        return false;
      }
      // 获取元素在页面中的位置
      // console.log(this.$refs.dingyue_box_ref.getBoundingClientRect());

      // document
      //   .getElementById("agentTitle")
      //   .scrollIntoView({ block: "start", behavior: "smooth" });
      // 跳转到指定位置并且平滑滚动
      // console.log(this.$el.querySelector(`.${classtext}`));
      this.$el
        .querySelector(`.${classtext}`)
        .scrollIntoView({ block: "start", behavior: "smooth" });

      global.setIndexDclass("");
    },
    blinkshandleHover(index) {
      this.bselectindex = index;
      // console.log(this.bselectindex);
    },
    sectionhandleHover(index) {
      if (this.w750 == 0) this.sectionindex = index;
    },
    blinkshandleLeave() {
      this.bselectindex = -1;
    },
    pphandleHover(index) {
      this.ppindex = index;
    },
    pphandleLeave() {},

    awardshandleHover(index) {
      this.awardsindex = index;
    },
    awardshandleLeave() {
      this.awardsindex = -1;
    },
  },
};
</script>


<style src="../assets/css/common.css" />
<style src="../assets/css/indexlm.css" />
<style src="../assets/css/indexlmxg.css" />

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
