var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"main"},[_c('a-layout',{staticClass:"mlayout"},[_c('a-layout',[_c('a-layout-content',{staticClass:"mobile indexLayoutContent",staticStyle:{"padding":"0px"}},[_c('div',{staticClass:"page mindex flex-col justify-start"},[_c('top-b',{ref:"topb",attrs:{"kind":`index`,"topbarhide":_vm.topbarhide,"tb":_vm.tb,"w750":_vm.w750,"topbarOpacity":_vm.topbarOpacity,"lag":_vm.lag,"bmshow":_vm.bmshow,"bmstate":_vm.bmstate,"bmid":_vm.bmid,"bkind":_vm.bkind},on:{"bmclose":_vm.bmclose}}),_c('div',{staticClass:"mswiper windowheight"},[_c('div',{staticClass:"swiper sc"},[_c('div',{class:`swiper-wrapper`},[_c('div',{class:`swiper-slide ssm ssm0`},[_c('div',{staticClass:"group_1 flex-col windowheight"},[(_vm.clist90.length > 0)?_c('div',{staticClass:"swiper sc0"},[_c('div',{staticClass:"swiper-wrapper"},[_vm._l((_vm.clist90),function(item,index){return [_c('div',{key:index,class:`swiper-slide ssl0 ss${index}`,style:(`background-image:url(${_vm.swiper0backimg});opacity:${
                              1 - _vm.opy1
                            };`)},[_c('div',{class:`mask mk0 ${
                                index == 0 ? 'mkmaskshow' : ''
                              }`,style:(`background-image:url(${
                                _vm.baseUrl + item.imgurl
                              });`),on:{"click":() => {
                                  _vm.godetall(
                                    item.url,
                                    item.pname,
                                    item.kind,
                                    item.id
                                  );
                                }}})])]})],2)]):_vm._e(),_c('div',{staticClass:"sc0zb"}),_c('div',{staticClass:"mkdots"},[_vm._l((_vm.clist90),function(item,index){return [_c('div',{key:index,class:`md ${
                            _vm.mdselectIndex == index ? 'mdselected' : ''
                          }`})]})],2)])]),_c('div',{class:`swiper-slide ssm ssm1`},[_c('div',{staticClass:"group_2 flex-row justify-center"},[_c('div',{staticClass:"group_2_in flex-col justify-between"},[_c('div',{staticClass:"group_2_1 flex-col"},[_c('div',{staticClass:"textitem",staticStyle:{"margin-top":"25rem","border-top":"1px solid #ccc"}}),_c('div',{staticClass:"textitem",staticStyle:{"margin-top":"32px"}},[_vm._v(" All Fields趣野无限，一个致力于探索户外生活方式的文化传播交流平台。 "),_c('br'),_c('br'),_vm._v(" 我们特设「"),_c('span',{staticClass:"bt"},[_vm._v("趣野CLUB")]),_vm._v("」、「"),_c('span',{staticClass:"bt"},[_vm._v("趣野好物")]),_vm._v("」、「"),_c('span',{staticClass:"bt"},[_vm._v("趣野风向")]),_vm._v("」等入口，引领多元户外生活体验、整合各圈层户外品牌、集结户外潮流文化动向，从活动、商品、资讯等多维度，为喜爱户外的人群提供一站式、可持续、个性化的户外生活方式解决方案。 "),_c('br'),_c('br')])]),_c('div',{staticClass:"group_2_2 flex-col"},[_c('span',{staticClass:"text_12"},[_c('img',{staticStyle:{"width":"680px"},attrs:{"src":"https://cdn.moheweb.com/vdb/lweb/imgs/allfields1.png"}})])])]),_c('img',{staticClass:"backimg",staticStyle:{"object-fit":"cover"},attrs:{"src":require("../assets/limgs/mb.png")}})])]),_c('div',{class:`swiper-slide ssm ssm2`},[_c('div',{staticClass:"swiper lsc0"},[_c('div',{staticClass:"swiper-wrapper"},[_c('div',{staticClass:"swiper-slide"},[_c('div',{staticClass:"group_3 flex-col",style:(`min-height:${_vm.pageHeight}px;`)},[_c('div',{staticClass:"g3 flex-col",style:(`opacity:${1 - _vm.opy3};`)},[_c('div',{staticClass:"g3in flex-col"},[_c('div',{staticClass:"gbar flex-row justify-between"},[_c('div',{class:`gtitles flex-col ${
                                    _vm.mselectIndex == 2 ? 'gtshow' : ''
                                  }`},[_c('span',{staticClass:"text_13",attrs:{"data-v-3f1e1d78":""}},[_c('img',{staticStyle:{"height":"68px"},attrs:{"src":"https://cdn.moheweb.com/vdb/lweb/imgs/title4.png"}})]),_c('span',{staticClass:"text_14",attrs:{"data-v-3f1e1d78":""}},[_vm._v("当户外成为生活常态")])]),_c('div',{staticClass:"btns flex-col"},[_c('router-link',{attrs:{"to":`/clubm/0/${_vm.lag}`}},[_c('div',{staticClass:"lbtn flex-row justify-center",staticStyle:{"margin-right":"4rem"}},[_c('div',{staticClass:"lbtnin flex-row justify-between"},[_c('span',{staticClass:"text-group_123"},[_vm._v("了解更多")]),_c('img',{staticClass:"image_186",attrs:{"data-v-3f1e1d78":"","referrerpolicy":"no-referrer","src":"https://cdn.moheweb.com/vdb/lweb/imgs/wj.png"}})])])]),_c('router-link',{attrs:{"to":`/clublistm/0/${_vm.lag}`}},[_c('div',{staticClass:"lbtn flex-row justify-center",staticStyle:{"margin-top":"20px"}},[_c('div',{staticClass:"lbtnin flex-row justify-between"},[_c('span',{staticClass:"text-group_123"},[_vm._v("报名通道")]),_c('img',{staticClass:"image_186",attrs:{"data-v-3f1e1d78":"","referrerpolicy":"no-referrer","src":"https://cdn.moheweb.com/vdb/lweb/imgs/wj.png"}})])])])],1)]),_c('div',{staticClass:"plist m0 moveing",staticStyle:{"top":"0px"}},[_vm._l((_vm.clist40),function(item,index){return [(index == 0)?_c('div',{key:index,class:`pitem ${
                                      _vm.mselectIndex == 2 ? 'showpitem' : ''
                                    }`,style:(`
                                      background-image: url('${
                                        _vm.baseUrl + item.imgurl
                                      }');
                                      top: 260px;
                                      left: 36px;
                                      animation-delay: 0s;
                                      `)},[_c('div',{staticClass:"pinfo",on:{"click":() => {
                                          _vm.godetall(
                                            item.url,
                                            item.pname,
                                            item.kind,
                                            item.id
                                          );
                                        }}},[_c('div',{staticClass:"micon"}),_c('div',{staticClass:"ptitle",domProps:{"innerHTML":_vm._s(item.pname)}}),_c('div',{staticClass:"pdes",domProps:{"innerHTML":_vm._s(item.des)}})])]):_vm._e(),(index == 1)?_c('div',{key:index,class:`pitem ${
                                      _vm.mselectIndex == 2 ? 'showpitem' : ''
                                    }`,style:(`
                                      background-image: url('${
                                        _vm.baseUrl + item.imgurl
                                      }');
                                      transform: scale(0.65);
                                      left: 300px;
                                      top: 600px;
                                      animation-delay: .4s;
                                      `)},[_c('div',{staticClass:"pinfo",on:{"click":() => {
                                          _vm.godetall(
                                            item.url,
                                            item.pname,
                                            item.kind,
                                            item.id
                                          );
                                        }}},[_c('div',{staticClass:"micon"}),_c('div',{staticClass:"ptitle",domProps:{"innerHTML":_vm._s(item.pname)}}),_c('div',{staticClass:"pdes",domProps:{"innerHTML":_vm._s(item.des)}})])]):_vm._e(),(index == 2)?_c('div',{key:index,class:`pitem ${
                                      _vm.mselectIndex == 2 ? 'showpitem' : ''
                                    }`,style:(`background-image: url('${
                                      _vm.baseUrl + item.imgurl
                                    }'); transform: scale(1.1);left: 120px; top: 1000px;}; animation-delay: .8s;`)},[_c('div',{staticClass:"pinfo",on:{"click":() => {
                                          _vm.godetall(
                                            item.url,
                                            item.pname,
                                            item.kind,
                                            item.id
                                          );
                                        }}},[_c('div',{staticClass:"micon"}),_c('div',{staticClass:"ptitle",domProps:{"innerHTML":_vm._s(item.pname)}}),_c('div',{staticClass:"pdes",domProps:{"innerHTML":_vm._s(item.des)}})])]):_vm._e()]})],2),_c('div',{staticClass:"zsqlist"},[_c('div',{staticClass:"zsqitem zi0 zsqselected"}),_c('div',{staticClass:"zsqitem zi1"}),_c('div',{staticClass:"zsqitem zi2"}),_c('div',{staticClass:"zsqitem zi3"}),_c('div',{staticClass:"zsqitem zi4"}),_c('div',{staticClass:"zsqitem zi5"}),_c('div',{staticClass:"zsqitem zi6"})])]),_c('img',{staticClass:"backimgback1",attrs:{"src":"https://cdn.moheweb.com/vdb/lweb/imgs/back1_3.png"}}),_c('div',{staticClass:"backimg"})])])])])])]),_c('div',{class:`swiper-slide ssm ssm3`},[_c('div',{staticClass:"swiper lsc1"},[_c('div',{staticClass:"swiper-wrapper"},[_c('div',{staticClass:"swiper-slide"},[_c('div',{staticClass:"group_4"},[_c('div',{staticClass:"g4 flex-col",style:(`min-height:${_vm.pageHeight}px;`)},[_c('div',{staticClass:"g4in flex-col"},[_c('div',{staticClass:"gbar flex-col justify-between"},[_c('div',{class:`gtitles flex-col m1 ${
                                    _vm.mselectIndex == 3 ? 'gtshow' : ''
                                  }`},[_c('span',{staticClass:"text_13",attrs:{"data-v-3f1e1d78":""}},[_c('img',{staticStyle:{"height":"68px"},attrs:{"src":"https://cdn.moheweb.com/vdb/lweb/imgs/title3.png"}})]),_c('span',{staticClass:"text_14",attrs:{"data-v-3f1e1d78":""}},[_vm._v("一起来期待 ALL FIELDS 趣野无限的首次正式亮相")])]),_c('img',{staticClass:"image_13",staticStyle:{"width":"510px","height":"1px","margin":"60px 0 0 0px"},attrs:{"referrerpolicy":"no-referrer","src":"https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPngd8f4410b8586cbf2d332f9e80509bbea4e70f7b4e9aa4394474c41f867fc9db0"}}),_c('div',{staticClass:"gdes flex-col"},[_c('div',{staticClass:"textline",staticStyle:{"left":"32px","top":"254px","width":"510px","font-size":"22px","font-family":"SourceHanSansCN-Light","font-weight":"300","text-align":"justify","line-height":"34px","margin-top":"20px"}},[_vm._v(" All Fields趣野无限联合上海体博会将于2023年11月23-25日在世博展览馆全面露出… ")]),_c('div',{staticClass:"btns flex-row justify-start",staticStyle:{"margin-top":"20px"}},[_c('div',{staticClass:"lbtn flex-row justify-center",staticStyle:{"margin-right":"32px"},on:{"click":() => {
                                          //bmopen(data33.id);
                                          _vm.bmopen();
                                        }}},[_c('div',{staticClass:"lbtnin flex-row justify-between"},[_c('span',{staticClass:"text-group_123"},[_vm._v("展商通道")]),_c('img',{staticClass:"image_186",attrs:{"data-v-3f1e1d78":"","referrerpolicy":"no-referrer","src":"https://cdn.moheweb.com/vdb/lweb/imgs/wj.png"}})])]),_c('div',{staticClass:"lbtn flex-row justify-center",on:{"click":() => {
                                          _vm.godetall(
                                            _vm.data33.url,
                                            _vm.data33.pname,
                                            _vm.data33.kind,
                                            _vm.data33.id
                                          );
                                        }}},[_c('div',{staticClass:"lbtnin flex-row justify-between"},[_c('span',{staticClass:"text-group_123"},[_vm._v("观众通道")]),_c('img',{staticClass:"image_186",attrs:{"data-v-3f1e1d78":"","referrerpolicy":"no-referrer","src":"https://cdn.moheweb.com/vdb/lweb/imgs/wj.png"}})])])])])]),_c('div',{staticClass:"zlist flex-row"},[_vm._l((_vm.clist31),function(item,index){return [_c('div',{key:index,staticClass:"zitemf flex-row justify-between",on:{"click":() => {
                                        _vm.godetall(
                                          item.url,
                                          item.pname,
                                          item.kind,
                                          item.id
                                        );
                                      }}},[_c('div',{staticClass:"zt"},[_c('div',{staticClass:"zimg"},[_c('img',{attrs:{"referrerpolicy":"no-referrer","src":item.imgurl.indexOf('https://') ==
                                            -1
                                              ? _vm.baseUrl + item.imgurl
                                              : item.imgurl}})]),_c('div',{staticClass:"title"},[_vm._v(" "+_vm._s(item.pname)+" ")]),_c('div',{staticClass:"des"},[_vm._v(_vm._s(item.des))])])])]})],2),_c('div',{staticStyle:{"position":"relative","width":"750px"}},[_c('router-link',{attrs:{"to":`/showm/0/${_vm.lag}`}},[_c('div',{staticClass:"lbtn flex-row justify-center",staticStyle:{"margin-left":"8rem","margin-top":"60px","margin-bottom":"80px"}},[_c('div',{staticClass:"lbtnin flex-row justify-between"},[_c('span',{staticClass:"text-group_123"},[_vm._v("了解更多")]),_c('img',{staticClass:"image_186",attrs:{"data-v-3f1e1d78":"","referrerpolicy":"no-referrer","src":"https://cdn.moheweb.com/vdb/lweb/imgs/wj.png"}})])])])],1)]),_c('img',{staticClass:"backimg",staticStyle:{"object-fit":"cover"},attrs:{"src":require("../assets/limgs/mb.png")}})])])])])])]),_c('div',{class:`swiper-slide ssm ssm4`},[_c('div',{staticClass:"group_5 flex-col windowheight"},[_c('div',{staticClass:"g5 flex-col"},[_c('div',{staticClass:"g5in flex-col"},[_c('div',{staticClass:"gbar flex-row justify-between"},[_c('div',{class:`gtitles flex-col ${
                              _vm.mselectIndex == 4 ? 'gtshow' : ''
                            }`},[_c('span',{staticClass:"text_13",staticStyle:{"color":"rgba(50, 50, 50, 1)"},attrs:{"data-v-3f1e1d78":""}},[_c('img',{staticStyle:{"height":"68px"},attrs:{"src":"https://cdn.moheweb.com/vdb/lweb/imgs/title2.png"}})]),_c('span',{staticClass:"text_14",staticStyle:{"color":"rgba(50, 50, 50, 1)"},attrs:{"data-v-3f1e1d78":""}},[_vm._v("ALL FIELDS让你「闭眼」购物~")])]),_c('div',{staticClass:"btns flex-row justify-end",staticStyle:{"opacity":"0"}},[_c('div',{staticClass:"lmore flex-row justify-center",staticStyle:{"margin-top":"20px","margin-bottom":"0px"}},[_c('router-link',{attrs:{"to":`/haowum/0/${_vm.lag}`}},[_c('div',{staticClass:"lmorein flex-row justify-between"},[_c('span',{staticClass:"text-group_123"},[_vm._v("更多好物")]),_c('img',{staticClass:"image_186 bjt",attrs:{"referrerpolicy":"no-referrer","src":"https://cdn.moheweb.com/vdb/lweb/imgs/bj.png"}}),_c('img',{staticClass:"image_186 wjt",staticStyle:{"display":"none"},attrs:{"referrerpolicy":"no-referrer","src":"https://cdn.moheweb.com/vdb/lweb/imgs/wj.png"}})])])],1)])]),_c('div',{staticClass:"swiper sc1"},[_c('div',{staticClass:"swiper-wrapper"},[_vm._l((_vm.clist20),function(item,index){return [_c('div',{key:index,class:`swiper-slide ss${index} ${
                                  index == 0 ? 'ssselected' : ''
                                }`},[_c('div',{staticClass:"ppitem"},[_c('div',{staticClass:"ppimg"},[_c('img',{attrs:{"src":_vm.baseUrl + item.imgurl}})]),_c('div',{staticClass:"ppinfo"},[_c('div',{staticClass:"pptitle"},[_vm._v(" "+_vm._s(item.pname)+" ")]),_c('div',{staticClass:"ppdes"},[_vm._v(_vm._s(item.des))]),(item.price != '0')?_c('div',{staticClass:"ppprice"},[_vm._v(" ￥"+_vm._s(_vm.takeprice(item.price))+" ")]):_vm._e(),(item.price != '0')?_c('div',{staticClass:"ppbtn"},[_c('a',{attrs:{"target":"_blank","href":item.url}},[_c('div',{staticClass:"lmore flex-row justify-center",staticStyle:{"margin-top":"48px","margin-bottom":"0px"}},[_c('div',{staticClass:"lmorein flex-row justify-between"},[_c('span',{staticClass:"text-group_123"},[_vm._v("立即购买")]),_c('img',{staticClass:"image_186 bjt",attrs:{"referrerpolicy":"no-referrer","src":"https://cdn.moheweb.com/vdb/lweb/imgs/bj.png"}}),_c('img',{staticClass:"image_186 wjt",staticStyle:{"display":"none"},attrs:{"referrerpolicy":"no-referrer","src":"https://cdn.moheweb.com/vdb/lweb/imgs/wj.png"}})])])])]):_vm._e()])])])]})],2),_c('div',{staticClass:"pndiv"},[_c('div',{staticClass:"toleft",on:{"click":_vm.swiper1Prev}}),_c('div',{staticClass:"swiper-pagination"}),_c('div',{staticClass:"toright",on:{"click":_vm.swiper1Next}})])])]),_c('img',{staticClass:"backimg",staticStyle:{"object-fit":"cover"},attrs:{"src":require("../assets/limgs/mb.png")}})]),(
                        _vm.winHeight >
                        _vm.group1h + _vm.group2h + _vm.group3h + _vm.group4h + 4000 + 4000
                      )?_c('div',{staticClass:"wzd2",style:(`opacity:${_vm.opy5};`)}):_vm._e()])]),_c('div',{class:`swiper-slide ssm ssm5`},[_c('div',{staticClass:"swiper lsc2"},[_c('div',{staticClass:"swiper-wrapper"},[_c('div',{staticClass:"swiper-slide"},[_c('div',{staticClass:"group_6 flex-col",style:(`-webkit-mask-size: ${_vm.masksize}%; ${
                            _vm.masksize == 300 ? '-webkit-mask:none' : ''
                          } `)},[_c('div',{staticClass:"g6gg"},[_c('div',{staticClass:"swiper sc6"},[_c('div',{staticClass:"swiper-wrapper"},[_vm._l((_vm.clist91),function(item,index){return [_c('div',{key:index,class:`swiper-slide`},[_c('div',{staticClass:"ggimg",style:(`background-image:url(${
                                        _vm.baseUrl + item.imgurl
                                      });`),on:{"click":() => {
                                          _vm.godetall(
                                            item.url,
                                            item.pname,
                                            item.kind,
                                            item.id
                                          );
                                        }}})])]})],2),_c('div',{staticClass:"swiper-pagination sp6"})])]),_c('div',{staticClass:"g6 flex-col"},[_c('div',{staticClass:"g6in flex-col"},[_c('div',{staticClass:"gbar flex-col justify-between"},[_c('div',{class:`gtitles flex-col m1 ${
                                    _vm.mselectIndex == 5 ? 'gtshow' : ''
                                  }`,staticStyle:{"display":"flex","flex-direction":"column","align-items":"flex-end","justify-content":"center"}},[_c('span',{staticClass:"text_13",staticStyle:{"width":"auto","text-align":"right"},attrs:{"data-v-3f1e1d78":""}},[_c('img',{staticStyle:{"height":"68px","margin-right":"160px"},attrs:{"src":"https://cdn.moheweb.com/vdb/lweb/imgs/title1.png"}})]),_c('span',{staticClass:"text_14",staticStyle:{"width":"auto","text-align":"right"},attrs:{"data-v-3f1e1d78":""}},[_vm._v("听说……这些都是ALL FIELDS的好朋友")]),_c('router-link',{attrs:{"to":`/huobanm/0/${_vm.lag}`}},[_c('div',{staticClass:"lmore flex-row justify-center",staticStyle:{"margin-top":"30px","margin-bottom":"30px","width":"208px","margin-right":"240px","opacity":"1"}},[_c('div',{staticClass:"lmorein flex-row justify-between"},[_c('span',{staticClass:"text-group_123"},[_vm._v("了解更多")]),_c('img',{staticClass:"image_186 bjt",attrs:{"referrerpolicy":"no-referrer","src":"https://cdn.moheweb.com/vdb/lweb/imgs/bj.png"}}),_c('img',{staticClass:"image_186 wjt",staticStyle:{"display":"none"},attrs:{"referrerpolicy":"no-referrer","src":"https://cdn.moheweb.com/vdb/lweb/imgs/wj.png"}})])])])],1),_c('div',{staticClass:"gdes flex-row justify-between",staticStyle:{"width":"100%","margin-top":"40px"}},[_c('div',{staticClass:"textline",staticStyle:{"margin-top":"1rem","width":"420px"}},[_c('div',{staticClass:"tline",staticStyle:{"position":"relative","width":"100%","border-bottom":"1px solid #aaa","margin-bottom":"20px"}}),_vm._v(" 户外博主们的日常生活是怎么样的？ "),_c('br'),_vm._v(" 户外品牌主理人每天都在忙什么？ "),_c('br'),_vm._v(" 户外专业大咖是怎样练成的？ ")])])]),_c('div',{staticClass:"g6plist"},[(_vm.flist10.length > 0)?_c('div',{staticClass:"pitem p6pi1",style:(`
                                    background-image: url('${_vm.baseUrl}${_vm.flist10[0].imgurl}');
                                  `)},[_c('router-link',{attrs:{"to":`/huobanm/0/${_vm.lag}`}},[_c('div',{staticClass:"pinfo"},[_c('div',{staticClass:"micon"}),_c('div',{staticClass:"title",domProps:{"innerHTML":_vm._s(`@${_vm.flist10[0].title}`)}})])])],1):_vm._e(),_c('span',{staticClass:"text_49",attrs:{"data-v-3f1e1d78":""}},[_vm._v("快来这里看看All Fields朋友圈的故事，他们并非一定是你仰望的对象，而是身边的你我他。")]),(_vm.flist10.length > 1)?_c('div',{staticClass:"pitem p6pi2",style:(`
                                    background-image: url('${_vm.baseUrl}${_vm.flist10[1].imgurl}');
                                  `)},[_c('router-link',{attrs:{"to":`/huobanm/0/${_vm.lag}`}},[_c('div',{staticClass:"pinfo"},[_c('div',{staticClass:"micon"}),_c('div',{staticClass:"title",domProps:{"innerHTML":_vm._s(`@${_vm.flist10[1].title}`)}})])])],1):_vm._e(),(_vm.flist10.length > 2)?_c('div',{staticClass:"pitem p6pi3",style:(`
                                    background-image: url('${_vm.baseUrl}${_vm.flist10[2].imgurl}');
                                  `)},[_c('router-link',{attrs:{"to":`/huobanm/0/${_vm.lag}`}},[_c('div',{staticClass:"pinfo"},[_c('div',{staticClass:"micon"}),_c('div',{staticClass:"title",domProps:{"innerHTML":_vm._s(`@${_vm.flist10[2].title}`)}})])])],1):_vm._e(),(_vm.flist10.length > 3)?_c('div',{staticClass:"pitem p6pi4",style:(`
                                    background-image: url('${_vm.baseUrl}${_vm.flist10[3].imgurl}');
                                  `)},[_c('router-link',{attrs:{"to":`/huobanm/0/${_vm.lag}`}},[_c('div',{staticClass:"pinfo"},[_c('div',{staticClass:"micon"}),_c('div',{staticClass:"title",domProps:{"innerHTML":_vm._s(`@${_vm.flist10[3].title}`)}})])])],1):_vm._e(),(_vm.flist10.length > 4)?_c('div',{staticClass:"pitem p6pi5",style:(`
                                    background-image: url('${_vm.baseUrl}${_vm.flist10[4].imgurl}');
                                  `)},[_c('router-link',{attrs:{"to":`/huobanm/0/${_vm.lag}`}},[_c('div',{staticClass:"pinfo"},[_c('div',{staticClass:"micon"}),_c('div',{staticClass:"title",domProps:{"innerHTML":_vm._s(`@${_vm.flist10[4].title}`)}})])])],1):_vm._e()]),_c('div',{staticClass:"logolist"},[_vm._l((_vm.flist11),function(item,index){return [_c('img',{key:index,staticClass:"litem",attrs:{"src":_vm.baseUrl + item.imgurl},on:{"click":() => {
                                        _vm.setlogoindex(index);
                                      }}})]})],2),_c('div',{staticClass:"g6btitle"})]),_c('div',{staticClass:"backimg"})])])])])])]),_c('div',{class:`swiper-slide ssm ssm6`},[_c('div',{staticClass:"group_7 flex-col windowheight"},[_c('div',{staticClass:"g7 flex-col"},[_c('div',{staticClass:"g7in flex-col"},[_c('div',{staticClass:"g7list"},[_c('div',{staticClass:"g7itemf flex-col"},[_c('div',{staticClass:"gbar flex-col justify-between"},[_c('div',{class:`gtitles flex-col m1 ${
                                  _vm.mselectIndex == 6 ? 'gtshow' : ''
                                }`},[_c('span',{staticClass:"text_13",staticStyle:{"color":""},attrs:{"data-v-3f1e1d78":""}},[_vm._v("趣野风向")]),_c('span',{staticClass:"text_14",attrs:{"data-v-3f1e1d78":""}},[_vm._v("更多视角更多脑洞突破想象")])]),_c('div',{staticClass:"gdes flex-col"},[_c('img',{staticStyle:{"width":"510px","height":"1px","margin-top":"60px"},attrs:{"referrerpolicy":"no-referrer","src":"https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPng903d0f58fe69ff2e0857602f2f6e8118f0471cc1617f9d48d428b5d75f40d513"}}),_c('div',{staticClass:"textline",staticStyle:{"margin-top":"10px"}},[_vm._v(" 涵盖热点趋势、亮点单品、兴趣清单、指南宝典、出行攻略等干货内容，毫不吝啬与你分享户外文化，并释出All Fields对于可持续环保、乡村振兴、潮流时尚等理念的态度和实践。 ")]),_c('div',{staticClass:"btns flex-row justify-between",staticStyle:{"margin-top":"60px"}},[_c('router-link',{attrs:{"to":`/fengxiangm/0/${_vm.lag}`}},[_c('div',{staticClass:"lmore flex-row justify-center",staticStyle:{"margin-top":"0px","margin-bottom":"0px","width":"208px"}},[_c('div',{staticClass:"lmorein flex-row justify-between"},[_c('span',{staticClass:"text-group_123"},[_vm._v("了解更多")]),_c('img',{staticClass:"image_186 bjt",attrs:{"referrerpolicy":"no-referrer","src":"https://cdn.moheweb.com/vdb/lweb/imgs/bj.png"}}),_c('img',{staticClass:"image_186 wjt",staticStyle:{"display":"none"},attrs:{"referrerpolicy":"no-referrer","src":"https://cdn.moheweb.com/vdb/lweb/imgs/wj.png"}})])])])],1)])])]),_c('div',{staticClass:"lslist"},[_vm._l((_vm.lslist),function(item,index){return [_c('div',{key:index,staticClass:"lsitem",on:{"click":() => {
                                    _vm.gotourl('fengxiangm', item.kind);
                                  }}},[_c('div',{staticClass:"micon"}),_c('div',{staticClass:"g7itemtext",domProps:{"innerHTML":_vm._s(item.des)}}),_c('div',{staticClass:"g7iteminfo"},[_c('div',{staticClass:"g7itemtitle",domProps:{"innerHTML":_vm._s(item.title)}}),_c('img',{staticClass:"image_jt",attrs:{"referrerpolicy":"no-referrer","src":"https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPnga3a5b20e0324082a486419c2b193dbce68f3a2cd2b92053dcbe69c15171ccc1e"}}),_c('img',{staticClass:"image_jtf",attrs:{"referrerpolicy":"no-referrer","src":"https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPng4e043656295421bba5a24fd6b62f055c22039be9e721c037df2d8ce3f02df8ed"}})]),_c('div',{staticClass:"bimg",style:(`background-image: url(${item.imgurl});`)})])]})],2)])]),_c('div',{staticClass:"backimg"})])])]),_c('div',{class:`swiper-slide ssm ssm7`},[_c('footer-b',{attrs:{"kind":`index`,"g8top":_vm.g8top,"g8left":_vm.g8left,"lag":_vm.lag}})],1)])])]),_c('div',{staticClass:"zzp"},[_c('img',{staticClass:"loadlogo",attrs:{"referrerpolicy":"no-referrer","src":require("../assets/limgs/logo.png")}}),_c('img',{staticClass:"loading",attrs:{"src":require("../assets/limgs/loading.png")}}),_c('div',{staticClass:"backimg"})]),(_vm.slshow)?_c('div',{staticClass:"showlogodiv"},[_c('div',{staticClass:"slclose",on:{"click":() => {
                  _vm.slclose();
                }}}),_c('div',{staticClass:"sldiv"},[_c('div',{staticClass:"ldiv"},[_c('div',{staticClass:"logoimg"},[_c('img',{attrs:{"src":_vm.baseUrl + _vm.flist11[_vm.logoindex].imgurl}})]),_c('div',{staticClass:"logoinfo"},[_c('div',{staticClass:"logotitle",domProps:{"innerHTML":_vm._s(_vm.flist11[_vm.logoindex].title)}}),_c('div',{staticClass:"logodes",domProps:{"innerHTML":_vm._s(_vm.flist11[_vm.logoindex].des)}})])]),_c('div',{staticClass:"ldiv2"},[_c('div',{staticClass:"title"},[_vm._v("相关话题")]),_c('div',{staticClass:"line"}),_c('div',{staticClass:"list"},[_vm._l((_vm.flist11[_vm.logoindex].mtipary),function(item,index){return [_c('div',{key:index,staticClass:"item"},[_c('div',{staticClass:"t"},[_c('a',{staticStyle:{"color":"#000"},attrs:{"href":item.link,"target":"_blank"}},[_vm._v(_vm._s(item.tip))])]),_c('a',{staticStyle:{"color":"#000"},attrs:{"href":item.link,"target":"_blank"}},[_c('img',{staticClass:"jt",attrs:{"referrerpolicy":"no-referrer","src":"https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPng293fdf47784f5f9695e686f2d9fce66c2a06c00689ebab17507b1a05e2617c89"}})])])]})],2)])])]):_vm._e()],1)])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }